import { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import { IMaskInput } from 'react-imask';
import { useNavigate } from 'react-router-dom';

import {
  API,
  END,
  GlobalContext,
  SCR,
  PKG,
  SimulacaoModal,
} from '../../../config/imports';

export default function ProcessoModal({ obj, on, changeActive, load, nobj }) {
  // console.log(obj);
  const { createSelectProp, curr, float } = PKG.SCR.Utils;
  const { mascaraMoeda } = SCR.Helpers;

  const navigate = useNavigate();
  const { a } = useContext(GlobalContext);
  const {
    bancos,
    operacoes,
    tabelas,
    faixas,
    imobiliarias,
    corretores,
    correspondentes,
  } = a;

  const [processo, setProcesso] = useState();

  const [loadings, setLoadings] = useState({
    nome: false,
    cpf: false,
    email: false,
    whats: false,
    processo: false,
  });

  const [data, setData] = useState({
    user_codigo: obj.USER_CODIGO,
    nome: obj.NOME,
    cpf: obj.CPF,
    email: obj.EMAIL,
    telefone: obj.WHATSAPP,
    correspondente_codigo: obj.CORRESPONDENTE_CODIGO || '',
    corretor_codigo: obj.CORRETOR_CODIGO || '',
    imobiliaria_codigo: obj.IMOBILIARIA_CODIGO || '',
    banco_id: obj.BANCO_ID,
    operacao: obj.OPERACAO,
    tabela: obj.TABELA,
    linha: obj.LINHA,
    taxa: obj.TAXA,
    headline: obj.HEADLINE || '',
    informacoes: obj.INFORMACOES || '',
    beneficiado: obj.BENEFICIADO,
    servidor_publico: obj.SERVIDOR_PUBLICO,
    anos_fgts: obj.ANOS_FGTS,
    dependentes: obj.DEPENDENTES,
    clt: obj.CLT,
    possui_imovel: obj.POSSUI_IMOVEL,
    possui_imovel_cidade: obj.POSSUI_IMOVEL_CIDADE || '',
    valor_imovel: obj.IMOVEL_VALOR,
    valor_avaliacao: obj.VALOR_AVALIACAO,
    valor_entrada: obj.VALOR_ENTRADA,
    valor_entrada_fgts: obj.VALOR_ENTRADA_FGTS,
    valor_entrada_recursos: obj.VALOR_ENTRADA_RECURSOS,
    valor_financiado: obj.VALOR_FINANCIADO,
    cidade_imovel: obj.IMOVEL_CIDADE,
    valor_renda: obj.VALOR_RENDA,
    mais_velho: obj.MAIS_VELHO,
    compartilhar_corretor: obj.COMPARTILHAR_CORRETOR,
  });

  const [dataMask, setDataMask] = useState({
    valor_imovel: mascaraMoeda(parseFloat(obj.IMOVEL_VALOR).toFixed(2)),
    valor_avaliacao: mascaraMoeda(parseFloat(obj.VALOR_AVALIACAO).toFixed(2)),
    valor_entrada: mascaraMoeda(parseFloat(obj.VALOR_ENTRADA).toFixed(2)),
    valor_entrada_fgts: mascaraMoeda(
      parseFloat(obj.VALOR_ENTRADA_FGTS).toFixed(2),
    ),
    valor_entrada_recursos: mascaraMoeda(
      parseFloat(obj.VALOR_ENTRADA_RECURSOS).toFixed(2),
    ),
    valor_financiado: mascaraMoeda(parseFloat(obj.VALOR_FINANCIADO).toFixed(2)),
    valor_renda: mascaraMoeda(parseFloat(obj.VALOR_RENDA).toFixed(2)),
  });

  useEffect(() => {
    bancos && createSelectProp(bancos, 'proc-banco', 'NOME', true, 'ID');
    operacoes && createSelectProp(operacoes, 'proc-operacao', 'NOME');
    tabelas && createSelectProp(tabelas, 'proc-tabela', 'NOME');
    faixas && createSelectProp(faixas, 'proc-linha', 'NOME');
    faixas && createSelectProp(faixas, 'proc-taxa', 'VALUE');

    imobiliarias &&
      createSelectProp(
        imobiliarias,
        'proc-imobiliaria',
        'NOME',
        true,
        'CODIGO',
      );
    corretores &&
      createSelectProp(corretores, 'proc-corretor', 'NOME', true, 'COD');
    correspondentes &&
      createSelectProp(
        correspondentes,
        'proc-correspondente',
        'NOME',
        true,
        'CODIGO',
      );
  }, [
    bancos,
    operacoes,
    tabelas,
    faixas,
    imobiliarias,
    corretores,
    correspondentes,
    createSelectProp,
  ]);

  const handleFilterCorretores = () => {
    let imobiliaria_cod = document.getElementById('proc-imobiliaria').value;
    let correts =
      corretores && corretores.filter((c) => c.CODIGO === imobiliaria_cod);
    correts && createSelectProp(correts, 'proc-corretor', 'NOME', false, 'COD');
  };

  const handleValor = (event) => {
    let entrada;
    let financiado;
    if (event.target.name === 'valor_entrada_recursos') {
      entrada = float(event.target.value) + float(data.valor_entrada_fgts);
      financiado = float(data.valor_imovel) - entrada;
    } else if (event.target.name === 'valor_entrada_fgts') {
      entrada = float(data.valor_entrada_recursos) + float(event.target.value);
      financiado = float(data.valor_imovel) - entrada;
    } else if (event.target.name === 'valor_imovel') {
      entrada =
        float(data.valor_entrada_recursos) + float(data.valor_entrada_fgts);
      financiado = float(event.target.value) - entrada;
    } else {
      entrada =
        float(data.valor_entrada_recursos) + float(data.valor_entrada_fgts);
      financiado = float(data.valor_imovel) - entrada;
    }
    setData({
      ...data,
      valor_entrada: entrada,
      valor_financiado: financiado,
      [event.target.name]: event.target.value,
    });
    setDataMask({
      ...dataMask,
      valor_entrada: mascaraMoeda(entrada),
      valor_financiado: mascaraMoeda(financiado),
      [event.target.name]: mascaraMoeda(event.target.value),
    });
  };

  // const handleLoadings = (field, option = false) => {
  //   setLoadings({
  //     ...loadings,
  //     [field]: option,
  //   });
  // };

  const handleSetData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSetDataChecked = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.checked,
    });
  };

  // const handleSave = async (event, field, endpt) => {
  //   event.preventDefault();
  //   let codigo = obj.SIM_USER_CODIGO;
  //   let value = data[field];
  //   try {
  //     handleLoadings(field, true);
  //     await API(endpt, {
  //       codigo,
  //       [field]: value,
  //     });
  //     if (field === 'telefone') {
  //       obj.whatsapp = value;
  //     } else {
  //       obj.proc.user[field] = value;
  //     }
  //     nobj(obj);
  //     handleLoadings(field, false);
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   handleLoadings(field, false);
  // };

  // const handleSaveNome = (event) =>
  //   handleSave(event, 'nome', END.user.update.nome);
  // const handleSaveCpf = (event) =>
  //   handleSave(event, 'cpf', END.user.update.cpf);
  // const handleSaveEmail = (event) =>
  //   handleSave(event, 'email', END.user.update.email);
  // const handleSaveTelefone = (event) =>
  //   handleSave(event, 'telefone', END.user.update.telefone);

  const handleCreateProcesso = async (event) => {
    event.preventDefault();

    let user_codigo = obj.SIM_USER_CODIGO;
    let simulacao_codigo = obj.SIM_CODIGO;

    // setData({
    //   ...data,
    //   correspondente_codigo: document.getElementById('proc-correspondente')
    //     .value,
    //   corretor_codigo: document.getElementById('proc-corretor').value,
    //   imobiliaria_codigo: document.getElementById('proc-imobiliaria').value,
    //   banco_id: document.getElementById('proc-banco').value,
    //   operacao: document.getElementById('proc-operacao').value,
    //   tabela: document.getElementById('proc-tabela').value,
    //   linha: document.getElementById('proc-linha').value,
    //   taxa: document.getElementById('proc-taxa').value,
    //   cidade_imovel: document.getElementById('proc-imovel_cidade').value,
    //   headline: document.getElementById('proc-headline').value,
    //   informacoes: document.getElementById('proc-informacoes').value,
    //   beneficiado: document.getElementById('proc-beneficiado').checked,
    //   servidor_publico: document.getElementById('proc-servidor_publico')
    //     .checked,
    //   anos_fgts: document.getElementById('proc-anos_fgts').checked,
    //   dependentes: document.getElementById('proc-dependentes').checked,
    //   clt: document.getElementById('proc-clt').checked,
    //   possui_imovel: document.getElementById('proc-possui_imovel').checked,

    //   valor_imovel: float(document.getElementById('proc-valor_imovel').value),
    //   valor_avaliacao: float(
    //     document.getElementById('proc-valor_avaliacao').value,
    //   ),
    //   valor_entrada:
    //     float(data.valor_entrada_recursos) + float(data.valor_entrada_fgts),
    //   valor_financiado:
    //     float(data.valor_imovel) -
    //     (float(data.valor_entrada_recursos) + float(data.valor_entrada_fgts)),
    // });

    try {
      setLoadings({
        ...loadings,
        processo: true,
      });
      const response = await API(END.processos.create, {
        user_codigo,
        simulacao_codigo,
        correspondente_codigo: data.correspondente_codigo,
        corretor_codigo: data.corretor_codigo,
        imobiliaria_codigo: data.imobiliaria_codigo,
        banco_id: data.banco_id,
        operacao: data.operacao,
        tabela: data.tabela,
        linha: data.linha,
        taxa: data.taxa,
        cidade_imovel: data.cidade_imovel,
        headline: data.headline,
        informacoes: data.informacoes,
        beneficiado: data.beneficiado,
        servidor_publico: data.servidor_publico,
        anos_fgts: data.anos_fgts,
        dependentes: data.dependentes,
        clt: data.clt,
        possui_imovel: data.possui_imovel,
        possui_imovel_cidade: data.possui_imovel_cidade,
        valor_imovel: data.valor_imovel,
        valor_avaliacao: data.valor_avaliacao,
        valor_entrada: data.valor_entrada,
        valor_entrada_fgts: data.valor_entrada_fgts,
        valor_entrada_recursos: data.valor_entrada_recursos,
        valor_financiado: data.valor_financiado,
        valor_renda: data.valor_renda,
        mais_velho: data.mais_velho,
        compartilhar_corretor: data.compartilhar_corretor,
      });

      const dt = response.data;
      // if (error) {
      //   console.error(error_message);
      //   Swal.fire({
      //     title: 'Ooops!',
      //     text: error_message,
      //     icon: 'error',
      //     confirmButtonText: 'Ok',
      //   });
      //   return;
      // } else {
      //   Swal.fire({
      //     title: 'Uhul!!',
      //     text: 'Deu Certo! Aguarde mais um pouquinho...',
      //     icon: 'success',
      //     confirmButtonText: 'Cool',
      //   });
      // }
      const processo_codigo = dt.data;
      setProcesso(processo_codigo);
    } catch (error) {
      console.error(error);
    }

    setLoadings({
      ...loadings,
      processo: false,
    });
  };

  if (processo) navigate(`/processo/${processo}`);

  return (
    <div className='container p-4'>
      <SimulacaoModal
        obj={obj}
        on={on}
        changeActive={changeActive}
        load={load}
      />
      <hr />
      <p className='mt-3'>Criar novo Processo</p>

      <Form className='p-0 m-0'>
        <div className='gorow justify-content-around flex-wrap'>
          <div className='d-flex flex-column'>
            <p className='mt-3 mb-0 ffSoft textContrastColor'>Cliente</p>
            <Form.Group className='mb-3 d-flex flex-row gocol375 goStart375  goLeft375 align-items-center '>
              <Form.Label className='m-0 fs10 wp75 textContrastLow'>
                Nome
              </Form.Label>
              <Form.Control
                size='sm'
                required
                type='text'
                id='proc-user_nome'
                name='nome'
                value={data.nome}
                onChange={(e) => handleSetData(e)}
                className='wp250'
              />
            </Form.Group>

            <Form.Group className='mb-3 d-flex flex-row gocol375 goStart375  goLeft375 align-items-center '>
              <Form.Label className='m-0 fs10 wp75 textContrastLow'>
                CPF
              </Form.Label>
              <Form.Control
                size='sm'
                required
                type='text'
                as={IMaskInput}
                mask='000.000.000-00'
                id='proc-user_cpf'
                name='cpf'
                value={data.cpf}
                onChange={(e) => handleSetData(e)}
                className='wp250'
              />
            </Form.Group>

            <Form.Group className='mb-3 d-flex flex-row gocol375 goStart375  goLeft375 align-items-center '>
              <Form.Label className='m-0 fs10 wp75 textContrastLow'>
                Whatsapp
              </Form.Label>
              <Form.Control
                size='sm'
                as={IMaskInput}
                mask='(00) 00000-0000'
                required
                type='text'
                id='proc-user_telefone'
                name='telefone'
                value={data.telefone}
                onChange={(e) => handleSetData(e)}
                className='wp250'
              />
            </Form.Group>

            <Form.Group className='mb-3 d-flex flex-row gocol375 goStart375  goLeft375 align-items-center '>
              <Form.Label className='m-0 fs10 wp75 textContrastLow'>
                E-mail
              </Form.Label>
              <Form.Control
                size='sm'
                required
                type='text'
                id='proc-user_email'
                value={data.email}
                name='email'
                onChange={(e) => handleSetData(e)}
                className='wp250'
              />
            </Form.Group>
            <p className='mt-3 mb-0 ffSoft textContrastColor'>Imóvel</p>

            <Form.Group className='mb-3 d-flex flex-row gocol375 goStart375 goLeft375 align-items-center '>
              <Form.Label className='m-0 fs10 wp125 textContrastLow'>
                Valor do Imóvel
              </Form.Label>
              <Form.Control
                size='sm'
                step={'0.1'}
                id='proc-valor_imovel'
                name='valor_imovel'
                className='wp200'
                type='text'
                required
                value={dataMask.valor_imovel}
                onChange={(e) => handleValor(e)}
                onKeyUp={(e) => handleValor(e)}
              />
            </Form.Group>

            <Form.Group className='mb-3 d-flex flex-row gocol375 goStart375 goLeft375 align-items-center '>
              <Form.Label className='m-0 fs10 wp125 textContrastLow'>
                Valor de Avaliação
              </Form.Label>
              <Form.Control
                size='sm'
                step={'0.1'}
                id='proc-valor_avaliacao'
                name='valor_avaliacao'
                value={dataMask.valor_avaliacao}
                className='wp200'
                type='text'
                required
                onChange={(e) => handleValor(e)}
                onKeyUp={(e) => handleValor(e)}
              />
            </Form.Group>
            <Form.Group className='mb-3 d-flex flex-row gocol375 goStart375 goLeft375 align-items-center '>
              <Form.Label className='m-0 fs10 wp125 textContrastLow'>
                Cidade do Imóvel
              </Form.Label>
              <Form.Control
                size='sm'
                required
                type='text'
                id='proc-imovel_cidade'
                name='imovel_cidade'
                className='wp200'
                value={data.cidade_imovel}
                onChange={(e) => handleSetData(e)}
              />
            </Form.Group>

            <p className='mt-3 mb-0 ffSoft textContrastColor'>Entrada</p>

            <Form.Group className='mb-3 d-flex flex-row gocol375 goStart375 goLeft375 align-items-center '>
              <Form.Label className='m-0 fs10 wp125 textContrastLow'>
                Valor de FGTS
              </Form.Label>
              <Form.Control
                size='sm'
                step={'0.1'}
                id='proc-valor_fgts'
                name='valor_entrada_fgts'
                className='wp200'
                type='text'
                required
                value={dataMask.valor_entrada_fgts}
                onChange={(e) => handleValor(e)}
                onKeyUp={(e) => handleValor(e)}
              />
            </Form.Group>

            <Form.Group className='mb-3 d-flex flex-row gocol375 goStart375 goLeft375 align-items-center '>
              <Form.Label className='m-0 fs10 wp125 textContrastLow'>
                Recursos Próprios
              </Form.Label>
              <Form.Control
                size='sm'
                step={'0.1'}
                id='proc-valor_recursos'
                name='valor_entrada_recursos'
                value={dataMask.valor_entrada_recursos}
                className='wp200'
                type='text'
                required
                onChange={(e) => handleValor(e)}
                onKeyUp={(e) => handleValor(e)}
              />
            </Form.Group>

            <div className='gorow align-content-center align-items-center mb-1'>
              <span className='me-1 wp-100 ffSoft txt-500'>Total</span>
              <span
                className='wp150 gorow align-items-center'
                id='proc-valor_entrada'
              >
                {curr(data.valor_entrada)}
              </span>
            </div>
            <span
              id='proc-entrada-percent'
              className='f08 mb-1 mt-0'
            ></span>

            <p className='mt-3 mb-0 ffSoft textContrastColor'>Financiamento</p>

            <div className='gorow align-content-center align-items-center mb-2'>
              <span className='me-1 wp125 ffSoft fw300 fs08'>Financiado</span>
              <span
                className='wp200 gorow align-items-center justify-content-center'
                id='proc-valor_financiado'
              >
                {curr(data.valor_financiado)}
              </span>
            </div>
            <Form.Group className='mb-3 d-flex flex-row gocol375 goStart375 goLeft375 align-items-center '>
              <Form.Label className='m-0 fs10 wp125 textContrastLow'>
                Renda
              </Form.Label>
              <Form.Control
                size='sm'
                step={'0.1'}
                id='proc-valor_renda'
                name='proc-valor_renda'
                className='wp200'
                type='text'
                required
                value={dataMask.valor_renda}
                onChange={(e) => handleValor(e)}
                onKeyUp={(e) => handleValor(e)}
              />
            </Form.Group>

            <Form.Group className='mb-3 d-flex flex-row gocol375 goStart375 goLeft375 align-items-center '>
              <Form.Label className='m-0 fs10 wp125 textContrastLow'>
                Mais Velho
              </Form.Label>
              <Form.Control
                size='sm'
                required
                type='text'
                value={data.mais_velho}
                id='proc-idade_velho'
                name='mais_velho'
                className='wp200'
                onChange={(e) => handleSetData(e)}
              />
            </Form.Group>

            <p className='mt-3 mb-0 ffSoft textContrastColor'>Detalhes</p>

            <div className='gorow align-content-center align-items-center mb-1'>
              <span className='me-1 wp100 ffSoft fw300 fs08'>Banco</span>
              <span className='wp-200 wp300-995 gorow align-items-center'>
                <Form.Select
                  size='sm'
                  id='proc-banco'
                  name='banco_id'
                  value={data.banco_id}
                  className='form-control wp250 faCenter'
                  onChange={(e) => handleSetData(e)}
                ></Form.Select>
              </span>
            </div>

            <div className='gorow align-content-center align-items-center mb-1'>
              <span className='me-1 wp100 ffSoft fw300 fs08'>Operação</span>
              <span className='wp-200 wp300-995 gorow align-items-center'>
                <Form.Select
                  name='operacao'
                  id='proc-operacao'
                  size='sm'
                  value={data.operacao}
                  className='form-control wp250 faCenter'
                  onChange={(e) => handleSetData(e)}
                ></Form.Select>
              </span>
            </div>

            <div className='gorow align-content-center align-items-center mb-1'>
              <span className='me-1 wp100 ffSoft fw300 fs08'>Tabela</span>
              <span className='wp-200 wp300-995 gorow align-items-center'>
                <Form.Select
                  name='tabela'
                  id='proc-tabela'
                  size='sm'
                  value={data.tabela}
                  className='form-control wp250 faCenter'
                  onChange={(e) => handleSetData(e)}
                ></Form.Select>
              </span>
            </div>

            <div className='gorow align-content-center align-items-center mb-1'>
              <span className='me-1 wp100 ffSoft fw300 fs08'>Linha</span>
              <span className='wp-200 wp300-995 gorow align-items-center'>
                <Form.Select
                  id='proc-linha'
                  size='sm'
                  name='linha'
                  value={data.linha}
                  className='form-control wp250 faCenter'
                  onChange={(e) => {
                    handleSetData(e);
                    const tx = faixas.find((f) => f.NOME === e.target.value);
                    setData({
                      ...data,
                      taxa: tx.VALUE,
                    });
                  }}
                ></Form.Select>
              </span>
            </div>

            <div className='gorow align-content-center align-items-center mb-1'>
              <span className='me-1 wp100 ffSoft fw300 fs08'>Taxa</span>
              <span className='wp-200 wp300-995 gorow align-items-center'>
                <Form.Control
                  name='taxa'
                  size='sm'
                  id='proc-taxa'
                  value={data.taxa}
                  className='form-control wp250 wp75 faCenter'
                  onChange={(e) => handleSetData(e)}
                ></Form.Control>
                <span className='ms-1 f08'>%</span>
              </span>
            </div>

            <div className='gorow align-content-center align-items-center mb-1'></div>
          </div>

          <div className='d-flex flex-column'>
            <p className='mt-3 mb-0 ffSoft textContrastColor'>Variáveis</p>

            <Form.Check
              type='checkbox'
              onChange={(e) => handleSetDataChecked(e)}
              id='proc-beneficiado'
              name='beneficiado'
              checked={data.beneficiado}
              label='Já foi Beneficiado MCMV'
              className='ffSoft fw300 fs12 my-2 wp300'
            />

            <Form.Check
              type='checkbox'
              id='proc-possui_imovel'
              name='possui_imovel'
              onChange={(e) => handleSetDataChecked(e)}
              checked={data.possui_imovel}
              label='Possui Imóvel Registrado'
              className='ffSoft fw300 fs12 my-2 wp300'
            />
            {/* </span>
              </div> */}

            {data.possui_imovel ? (
              <Form.Group className='mb-3 d-flex flex-row gocol375 goStart375 goLeft375 align-items-center '>
                <Form.Label className='m-0 fs10 wp50 textContrastLow'>
                  Cidade
                </Form.Label>

                <Form.Control
                  size='sm'
                  required
                  type='text'
                  id='proc-possui_imovel_cidade'
                  name='possui_imovel_cidade'
                  value={data.possui_imovel_cidade}
                  placeholder='Cidade'
                  className='form-control  wp300'
                  onChange={(e) => handleSetData(e)}
                />
              </Form.Group>
            ) : null}
            {/* </div> */}

            <Form.Check
              type='checkbox'
              id='proc-servidor_publico'
              name='servidor_publico'
              onChange={(e) => handleSetDataChecked(e)}
              checked={data.servidor_publico}
              label='Servidor Público'
              className='ffSoft fw300 fs12 my-2 wp300'
            />

            <Form.Check
              type='checkbox'
              id='proc-anos_fgts'
              name='anos_fgts'
              checked={data.anos_fgts}
              onChange={(e) => handleSetDataChecked(e)}
              label='Possui 3 anos de FGTS'
              className='ffSoft fw300 fs12 my-2 wp300'
            />

            <Form.Check
              type='checkbox'
              id='proc-dependentes'
              name='dependentes'
              onChange={(e) => handleSetDataChecked(e)}
              checked={data.dependentes}
              label='Possui Dependentes'
              className='ffSoft fw300 fs12 my-2 wp300'
            />

            <Form.Check
              type='checkbox'
              id='proc-clt'
              name='clt'
              checked={data.clt}
              onChange={(e) => handleSetDataChecked(e)}
              label='Atualmente registrado CLT'
              className='ffSoft fw300 fs12 my-2 wp300'
            />

            <div className='my-2'>
              <p className='my-3 ffSoft textContrastColor'>Acompanhamento</p>

              <Form.Group className='mb-3 d-flex flex-row gocol375 goStart375 goLeft375 align-items-center '>
                <Form.Label className='m-0 fs10 wp125 textContrastLow'>
                  Correspondente
                </Form.Label>

                <Form.Select
                  size='sm'
                  id='proc-correspondente'
                  name='correspondente_codigo'
                  value={data.correspondente_codigo}
                  className='form-control wp250'
                  onChange={(e) => handleSetData(e)}
                ></Form.Select>
              </Form.Group>

              <Form.Group className='mb-3 d-flex flex-row gocol375 goStart375 goLeft375 align-items-center '>
                <Form.Label className='m-0 fs10 wp125 textContrastLow'>
                  Imobiliária
                </Form.Label>

                <Form.Select
                  id='proc-imobiliaria'
                  size='sm'
                  name='imobiliaria_codigo'
                  value={data.imobiliaria_codigo}
                  className='form-control wp250'
                  onChange={(e) => {
                    handleSetData(e);
                    handleFilterCorretores();
                  }}
                ></Form.Select>
              </Form.Group>

              <Form.Group className='mb-3 d-flex flex-row gocol375 goStart375 goLeft375 align-items-center '>
                <Form.Label className='m-0 fs10 wp125 textContrastLow'>
                  Imobiliária
                </Form.Label>

                <Form.Select
                  name='corretor_codigo'
                  size='sm'
                  id='proc-corretor'
                  value={data.corretor_codigo}
                  className='form-control wp250'
                  onChange={(e) => handleSetData(e)}
                ></Form.Select>
              </Form.Group>

              <Form.Check
                type='checkbox'
                id='proc-compartilhar'
                disabled
                name='compartilhar_corretor'
                checked={data.compartilhar_corretor}
                onChange={(e) => handleSetDataChecked(e)}
                label='Compartilhar Informações com Corretor'
                className='ffSoft fw300 fs12 mt-3 mb-0 wp300'
              />
              <p className='mt-0 mb-5 cRed fs09 wp350'>
                Para alterar essa opção, entre em contato com o corretor e o
                cliente
              </p>

              <Form.Group className='my-3 d-flex flex-row gocol375 goStart375 goLeft375 align-items-center '>
                <Form.Label className='m-0 fs10 wp125 textContrastLow'>
                  Outras Informações
                </Form.Label>
                <Form.Control
                  size='sm'
                  required
                  as='textarea'
                  rows={4}
                  id='proc-informacoes'
                  name='informacoes'
                  className='form-control wp250'
                  onChange={(e) => handleSetData(e)}
                  value={data.informacoes}
                />
              </Form.Group>

              <Form.Group className='mb-3 d-flex flex-row gocol375 goStart375 goLeft375 align-items-center '>
                <Form.Label className='m-0 fs10 wp125 textContrastLow'>
                  Breve Descrição
                </Form.Label>
                <Form.Control
                  size='sm'
                  required
                  as='textarea'
                  rows={2}
                  id='proc-headline'
                  name='headline'
                  value={data.headline}
                  className='form-control wp250'
                  onChange={(e) => handleSetData(e)}
                />
              </Form.Group>
            </div>

            {loadings.processo ? (
              <Spinner
                animation='border'
                variant='success'
                size='sm'
              />
            ) : (
              <Button
                variant='primary'
                size='sm'
                onClick={handleCreateProcesso}
              >
                Criar Processo
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
}
