import axios from 'axios';

const ORDS =
  'https://g3c32193a94f6bf-bwhabita.adb.sa-saopaulo-1.oraclecloudapps.com/ords/habita';
const HAPI = 'https://api.bewook.com/habita';

const endpoints = {
  processo: {
    create: {
      base: ORDS,
      endpoint: '/api/processos/c/',
      method: 'post',
      content: 'json',
    },
    mail: {
      base: HAPI,
      endpoint: '/mail/simulacao/',
      method: 'post',
      content: 'json',
    },
    // telegram: {
    //   base: HAPI,
    //   endpoint: '/telegram/api/notify_admin',
    //   method: 'post',
    // },
    update: {
      base: ORDS,
      endpoint: '/api/processos/u/full/',
      method: 'post',
      content: 'json',
    },
  },
  realstate: {
    read: {
      base: ORDS,
      endpoint: '/api/realstate/r/',
      method: 'post',
      content: 'json',
    },
  },
  cidades: {
    read: {
      base: ORDS,
      endpoint: '/api/cidades/r/',
      method: 'get',
      content: 'json',
    },
  },
  operacoes: {
    read: {
      base: ORDS,
      endpoint: '/api/operacoes/r/',
      method: 'get',
      content: 'json',
    },
  },
};

async function API(endpoint, body = {}, brand = 'hamanncorp') {
  const CONTENT = () => {
    switch (endpoint.content) {
      case 'files':
        return 'multipart/form-data';
      default:
        return 'application/json';
    }
  };

  const content = CONTENT();

  const api = axios.create({
    baseURL: endpoint.base,
    headers: {
      'Content-Type': content,
    },
  });
  let response;

  const token = sessionStorage.getItem('@App:token');
  api.defaults.headers.Authorization = token;
  body.token = token;
  body.brand = brand;
  if (endpoint.method === 'post') {
    response = await api.post(endpoint.endpoint, body);
  } else {
    response = await api.get(endpoint.endpoint);
  }
  if (
    response.data.success === false &&
    response.data.message === 'Token inválido'
  ) {
    window.location.href = '/logout';
  }
  return response;
}

export async function getCidades(brand) {
  const response = await API(endpoints.cidades.read, {}, brand);
  return response.data;
}

export async function getOperacoes(brand) {
  const response = await API(endpoints.operacoes.read, {}, brand);
  return response.data;
}

export async function getRealstate(brand, alias) {
  const response = await API(endpoints.realstate.read, { alias }, brand);
  return response.data;
}

export async function createProcesso(brand, body) {
  const response = await API(endpoints.processo.create, body, brand);
  return response.data;
}

export async function sendMail(brand, body) {
  const response = await API(endpoints.processo.mail, body, brand);
  return response.data;
}

export async function updateProcesso(brand, body) {
  const response = await API(endpoints.processo.update, body, brand);
  return response.data;
}
