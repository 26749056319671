import { useContext, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';

import {
  API,
  END,
  GlobalContext,
  PKG,
  EtapaIcon,
} from '../../../config/imports';

function Processos() {
  const { checkLogin, logout, store } = useContext(GlobalContext);
  const { theme } = useContext(PKG.PackageContext);
  const { curr } = PKG.SCR.Utils;

  const { apoio } = store();
  const etapas = apoio
    .filter((info) => info.TABELA === 'etapas')
    .map((etapa) => {
      return {
        ID: etapa.ID,
        ETAPA: etapa.NOME,
        SEQUENCIA: etapa.VALUE,
        ESTIMATIVA: etapa.VAR_VALUE,
      };
    });

  const [arrayProcessos, setArrayProcessos] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);

  const handleUpdate = () => {
    setUpdate(!update);
  };

  useEffect(() => {
    const get_processos = async () => {
      setLoading(true);
      try {
        const response = await API(END.processos.read.full, {});
        const data = response.data;

        const newData = data && data.map((proc) => {
          const etapa = etapas.find(
            (etapa) => etapa.ID === proc.ETAPA_ATUAL_ID,
          );
          return {
            ...proc,
            ETAPA: etapa.ETAPA,
          };
        });
        setArrayProcessos(newData);
        setFiltered(newData);
      } catch (error) {
        console.log(error.response.data.message);
      }
      setLoading(false);
    };
    const status = checkLogin();
    if (status) {
      get_processos();
    } else {
      logout();
    }
    // eslint-disable-next-line
  }, [logout, update]);

  if (loading)
    return (
      <>
        <PKG.Loading />
      </>
    );

  const filter_processos = () => {
    const terms = document.getElementById('filter-procs').value;

    if (terms.length > 2) {
      let filtered_array = arrayProcessos.filter(
        ({
          IMOVEL_CIDADE,
          IMOVEL_DESCRICAO,
          CORRESPONDENTE_NOME,
          CORRETOR_NOME,
          IMOBILIARIA_NOME,
          WHATSAPP,
          ETAPA,
        }) =>
          IMOVEL_CIDADE.toLowerCase().includes(terms.toLowerCase()) ||
          IMOVEL_DESCRICAO.toLowerCase().includes(terms.toLowerCase()) ||
          CORRESPONDENTE_NOME.toLowerCase().includes(terms.toLowerCase()) ||
          CORRETOR_NOME.toLowerCase().includes(terms.toLowerCase()) ||
          IMOBILIARIA_NOME.toLowerCase().includes(terms.toLowerCase()) ||
          WHATSAPP.toLowerCase().includes(terms.toLowerCase())||
          ETAPA.toLowerCase().includes(terms.toLowerCase()),
      );
      setFiltered(filtered_array);
    }

    if (terms.length < 3) {
      setFiltered(arrayProcessos);
    }
  };

  return (
    <div className='container mt-5'>
      <div className='d-flex flex-row align-items-center justify-content-start cBlue '>
        <span className='fs20 ffSoft textContrastColor '>Processos</span>
        <PKG.IconsUI
          info={PKG.ui.action.update}
          clsnm='svg14 ms-2 cLink hovGreen'
          click={handleUpdate}
          tooltip='Atualizar lista de processos'
        />
      </div>

      <p className='mt-2 mb-5'>
        <span className='me-2 ffSoft'>Filtrar por</span>
        <input
          type='text'
          id='filter-processos'
          className='filterTable'
          onChange={filter_processos}
          pattern='.{3,20}'
        />
      </p>

      {arrayProcessos.length > 0 || filtered.length > 0 ? (
        <Table
          responsive
          striped
          bordered
          hover
          className={`mb-3`}
          variant={theme}
        >
          <thead className='fs10 fw600 ffSoft faCenter'>
            <tr>
              <th>Correspondente</th>
              <th>Corretor</th>
              <th>Imobiliária</th>
              <th>Cidade</th>
              <th>Operação</th>
              <th>Valor</th>
              <th>Cliente</th>
              <th>Telefone</th>
              <th>Etapa Atual</th>
              <th>Abrir</th>
            </tr>
          </thead>
          <tbody>
            {filtered &&
              filtered.map(
                ({
                  PROCESSO_CODIGO,
                  CORRESPONDENTE_NOME,
                  CORRETOR_NOME,
                  IMOBILIARIA_NOME,
                  IMOVEL_CIDADE,
                  IMOVEL_DESCRICAO,
                  IMOVEL_VALOR,
                  NOME,
                  WHATSAPP,
                  ETAPA,
                }) => (
                  <tr
                    key={PROCESSO_CODIGO}
                    className='tableSimulacoes fs10'
                  >
                    <td>{CORRESPONDENTE_NOME || '-'}</td>
                    <td>{CORRETOR_NOME || '-'}</td>
                    <td>{IMOBILIARIA_NOME || '-'}</td>
                    <td>{IMOVEL_CIDADE}</td>
                    <td>{IMOVEL_DESCRICAO}</td>
                    <td>{curr(IMOVEL_VALOR)}</td>
                    <td>{NOME}</td>
                    <td>{WHATSAPP}</td>
                    <td>
                      <EtapaIcon
                        etapa={ETAPA}
                        className='me-1'
                      />
                      <span className='ms-1'>{ETAPA}</span>
                    </td>
                    <td>
                      <span className='d-flex p-2'>
                        <PKG.IconsUI
                          info={PKG.ui.folder.open}
                          clsnm='svg14 mx-2 cGreen mousePointerHover '
                          tooltip='Abrir processo'
                          link={`/processo/${PROCESSO_CODIGO}`}
                        />
                      </span>
                    </td>
                  </tr>
                ),
              )}
          </tbody>
        </Table>
      ) : (
        <p className='fs10 ffSoft'>Nenhum processo encontrado</p>
      )}
    </div>
  );
}

export default Processos;
