import { BadgeNumber } from '../../../config/imports';

export default function ModalLine({
  title,
  first,
  second = null,
  resumo = false,
  showNumber = true,
  clsnmFirst = '',
  clsnmSecond = '',
}) {
  const wd_title = resumo ? 'fw400 wp150 wpMin150 fs12' : 'fs10 wp100 wpMin100 textContrastLow';
  return (
    <div className={`p-1 gorow gocol300 ${ resumo ? '' : 'border-bottom border-secondary ' } `}>
      <span className={` ${wd_title} ${clsnmFirst}`}>{title}</span>
      <div className='gocol'>
        <span
          className={`gorow align-items-center breakAvoid mb-1 ${clsnmFirst}`}
        >
          {showNumber && <BadgeNumber number={1} />}
          <span className='breakWord'>{first}</span>
        </span>
        {second ? (
          <span
            className={`gorow align-items-center breakAvoid mb-1 ${clsnmSecond}`}
          >
            {showNumber && <BadgeNumber number={2} />}
            <span className='breakWord'>{second}</span>
          </span>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
