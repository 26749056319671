import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IMaskInput } from 'react-imask';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import * as SIMULACAO_JS from './script';
import './style.css';
import { ThreeDots } from 'react-loader-spinner';

import FormGroup from '../../../common/FormGroup';

import {
  getCidades,
  getOperacoes,
  getRealstate,
  createProcesso,
  sendMail,
  updateProcesso,
} from './requests';

export default function Simulador({
  full = true,
  useValidation = true,
  updateForm = false,
  theme = 'light',
  initialObject = null,
  upd = null,
  brand = 'hamanncorp',
  images,
  useBlueBg = true,
}) {
  const { corretor } = useParams();
  const navigate = useNavigate();

  const {
    data_int,
    is_valid_phone,
    is_compound_name,
    is_valid_cpf,
    is_valid_email,
    is_valid_nascimento,
    mascaraMoeda,
    numberFormat,
    numberReFormat,
    not_blank,
  } = SIMULACAO_JS;

  const IMOVEL_DESCRICAO = {
    1: 'Aquisição de Imóvel Novo',
    2: 'Construção',
    3: 'Reforma ou Ampliação',
    4: 'Aquisição de Imóvel Usado',
    6: 'Aquisição de Terreno',
    7: 'Empréstimo Garantido por Imóvel',
    11: 'Imóveis CAIXA',
  };

  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [operations, setOperations] = useState([]);
  const [simulacao, setSimulacao] = useState(
    initialObject
      ? {
          ...initialObject,
          IMOVEL_VALOR: numberFormat(initialObject.IMOVEL_VALOR),
          RENDA: numberFormat(initialObject.RENDA),
          FGTS: numberFormat(initialObject.FGTS),
          RECURSOS: numberFormat(initialObject.RECURSOS),
          OUTRO_RENDA: numberFormat(initialObject.OUTRO_RENDA),
          OUTRO_FGTS: numberFormat(initialObject.OUTRO_FGTS),
          OUTRO_RECURSOS: numberFormat(initialObject.OUTRO_RECURSOS),
        }
      : {
          WHATSAPP: '',
          EMAIL: '',
          NOME: '',
          NASCIMENTO: '',
          CPF: '',
          SIMULACAO_COMPLETA: full ? 1 : 0,
          IMOVEL_VALOR: '',
          IMOVEL_CIDADE: 'TAUBATE',
          IMOVEL_CODIGO: '',
          IMOVEL_DESCRICAO: '',
          COMPOR: 0,
          ESTADO_CIVIL: '',
          RELACIONAMENTO: '',
          RENDA: '',
          FGTS: '',
          RECURSOS: '',
          COMPROVAR: [],
          OUTRO_NOME: '',
          OUTRO_NASCIMENTO: '',
          OUTRO_CPF: '',
          OUTRO_WHATSAPP: '',
          OUTRO_RENDA: '',
          OUTRO_FGTS: '',
          OUTRO_RECURSOS: '',
          OUTRO_COMPROVAR: [],
          BENEFICIADO: '',
          SERVIDOR_PUBLICO: '',
          ANOS_FGTS: '',
          DEPENDENTES: '',
          CLT: '',
          POSSUI_IMOVEL: '',
          POSSUI_IMOVEL_CIDADE: '',
          INFORMACOES: '',
          COMPARTILHAR_CORRETOR: 1,
          CORRETOR: '',
          IMOBILIARIA: '',
          TERMOS: 0,
          IMOBILIARIA_CODIGO: '',
          CORRETOR_CODIGO: '',
        },
  );

  const [simulacaoHid, setSimulacaoHid] = useState(
    initialObject
      ? {
          IMOVEL_VALOR: initialObject.IMOVEL_VALOR
            ? numberReFormat(initialObject.IMOVEL_VALOR)
            : 0,
          RENDA: initialObject.RENDA ? numberReFormat(initialObject.RENDA) : 0,
          FGTS: initialObject.FGTS ? numberReFormat(initialObject.FGTS) : 0,
          RECURSOS: initialObject.RECURSOS
            ? numberReFormat(initialObject.RECURSOS)
            : 0,
          OUTRO_RENDA: initialObject.OUTRO_RENDA
            ? numberReFormat(initialObject.OUTRO_RENDA)
            : 0,
          OUTRO_FGTS: initialObject.OUTRO_FGTS
            ? numberReFormat(initialObject.OUTRO_FGTS)
            : 0,
          OUTRO_RECURSOS: initialObject.OUTRO_RECURSOS
            ? numberReFormat(initialObject.OUTRO_RECURSOS)
            : 0,
        }
      : {
          IMOVEL_VALOR: 0,
          RENDA: 0,
          FGTS: 0,
          RECURSOS: 0,
          OUTRO_RENDA: 0,
          OUTRO_FGTS: 0,
          OUTRO_RECURSOS: 0,
        },
  );

  const get_info = async () => {
    try {
      const [citiesResponse, operationsResponse] = await Promise.all([
        getCidades(brand),
        getOperacoes(brand),
      ]);

      if (corretor) {
        const corretorData = await getRealstate(brand, corretor);
        if (corretorData.data) {
          const rst = corretorData.data[0];
          setSimulacao({
            ...simulacao,
            CORRETOR: rst.CORRETOR_NOME,
            CORRETOR_CODIGO: rst.CORRETOR_CODIGO,
            IMOBILIARIA: rst.IMOBILIARIA_NOME,
            IMOBILIARIA_CODIGO: rst.IMOBILIARIA_CODIGO,
          });
        }
      }
      setCities(citiesResponse.items);
      setOperations(operationsResponse);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    get_info();
    // eslint-disable-next-line
  }, []);

  const loading_dots = () => {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center text-center w100 vh100 p-3'>
        <img
          src={images[theme].logotext}
          className='my-3 w60 wpMax250'
          alt='logo'
        />
        <ThreeDots
          visible={true}
          height='80'
          width='80'
          color='#0084ff'
          radius='9'
          ariaLabel='three-dots-loading'
          wrapperStyle={{}}
          wrapperClass=''
        />
        <p className='mt-4 mb-2 ffSoft fs08'>Aguarde...</p>
      </div>
    );
  };

  const updateSimulacaoState = (key, value) => {
    setSimulacao({ ...simulacao, [key]: value });
    if (key in simulacaoHid) {
      setSimulacao({
        ...simulacao,
        [key]: mascaraMoeda(value),
      });
      setSimulacaoHid({
        ...simulacaoHid,
        [key]: numberReFormat(value),
      });
    }
  };

  const updateState = (event, isNumber = false) => {
    const element = document.getElementById(event.target.id);
    if (isNumber) {
      setSimulacao({
        ...simulacao,
        [event.target.id]: mascaraMoeda(element.value),
      });
      setSimulacaoHid({
        ...simulacaoHid,
        [event.target.id]: numberReFormat(element.value),
      });
    } else {
      setSimulacao({ ...simulacao, [event.target.id]: element.value });
    }
  };

  const checkButtonsFields = () => {
    let errorMessage = '';
    let requiredFields = ['IMOVEL_DESCRICAO', 'ESTADO_CIVIL'];
    if (full && simulacao.compor) {
      requiredFields.push('relacionamento');
    }
    const fieldReference = {
      IMOVEL_DESCRICAO: 'Tipo de Financiamento',
      ESTADO_CIVIL: 'Seu Estado Civil',
      RELACIONAMENTO: 'Relacionamento com a pessoa que irá compor renda',
      COMPROVAR: 'Sua comprovação de renda',
      OUTRO_COMPROVAR: 'Comprovação de renda da pessoa que irá compor renda',
    };

    requiredFields.forEach((field) => {
      if (!not_blank(simulacao[field])) {
        document.getElementById(`DIV_${field}`).classList.add('invalid-box');
        errorMessage = `${errorMessage} <span className="swalDivErrorSimulacao-item">${fieldReference[field]}</span>`;
      } else {
        document.getElementById(`DIV_${field}`).classList.remove('invalid-box');
      }
    });

    const comprovacaoFields =
      full && simulacao.compor
        ? ['COMPROVAR', 'OUTRO_COMPROVAR']
        : ['COMPROVAR'];

    comprovacaoFields.forEach((field) => {
      if (simulacao[field].length > 0) {
        document.getElementById(`DIV_${field}`).classList.remove('invalid-box');
      } else {
        document.getElementById(`DIV_${field}`).classList.add('invalid-box');
        errorMessage = `${errorMessage} <span className="swalDivErrorSimulacao-item">${fieldReference[field]}</span>`;
      }
    });

    if (errorMessage.length > 0) {
      errorMessage = `<div id="swalDivErrorSimulacao"><p id="swalDivErrorSimulacao-title">Preencha os campos obrigatórios:</p><div  id="swalDivErrorSimulacao-items">${errorMessage}</div></div>`;
    }

    return errorMessage;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const checked = useValidation ? checkButtonsFields() : '';

    if (checked.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        background: theme === 'light' ? '#fff' : '#333',
        html: checked,
        iconColor: theme === 'light' ? '#aa0011' : '#aa0011c2',
        customClass: {
          title: 'fs24 fw600 textContrastColor',
          htmlContainer: 'fs12',
        },
      });
      return;
    }

    setLoading(true);

    setSimulacao({
      ...simulacao,
      ANOS_FGTS: parseInt(simulacao.ANOS_FGTS),
      DEPENDENTES: parseInt(simulacao.DEPENDENTES),
      CLT: parseInt(simulacao.CLT),
      POSSUI_IMOVEL: parseInt(simulacao.POSSUI_IMOVEL),
      BENEFICIADO: parseInt(simulacao.BENEFICIADO),
      SERVIDOR_PUBLICO: parseInt(simulacao.SERVIDOR_PUBLICO),
      IMOVEL_DESCRICAO: IMOVEL_DESCRICAO[simulacao.IMOVEL_CODIGO],
    });

    try {
      const payload = {
        ...simulacao,
        NASCIMENTO: simulacao.NASCIMENTO
          ? data_int(simulacao.NASCIMENTO)
          : null,
        OUTRO_NASCIMENTO:
          full && simulacao.COMPOR && simulacao.OUTRO_NASCIMENTO
            ? data_int(simulacao.OUTRO_NASCIMENTO)
            : null,
        IMOVEL_DESCRICAO: IMOVEL_DESCRICAO[simulacao.IMOVEL_CODIGO],
        COMPROVAR: simulacao.COMPROVAR ? simulacao.COMPROVAR.toString() : null,
        OUTRO_COMPROVAR: simulacao.OUTRO_COMPROVAR
          ? simulacao.OUTRO_COMPROVAR.toString()
          : null,
        IMOVEL_VALOR: simulacaoHid.IMOVEL_VALOR,
        RENDA: simulacaoHid.RENDA,
        FGTS: simulacaoHid.FGTS,
        RECURSOS: simulacaoHid.RECURSOS,
        OUTRO_RENDA: simulacaoHid.OUTRO_RENDA,
        OUTRO_FGTS: simulacaoHid.OUTRO_FGTS,
        OUTRO_RECURSOS: simulacaoHid.OUTRO_RECURSOS,
        ANOS_FGTS: parseInt(simulacao.ANOS_FGTS),
        DEPENDENTES: parseInt(simulacao.DEPENDENTES),
        CLT: parseInt(simulacao.CLT),
        POSSUI_IMOVEL: parseInt(simulacao.POSSUI_IMOVEL),
        BENEFICIADO: parseInt(simulacao.BENEFICIADO),
        SERVIDOR_PUBLICO: parseInt(simulacao.SERVIDOR_PUBLICO),
      };

      const response = updateForm
        ? await updateProcesso(brand, payload)
        : await createProcesso(brand, payload);

      if (!updateForm) {
        const formData = new FormData();
        Object.keys(simulacao).forEach((key) => {
          formData.append(key, simulacao[key]);
        });

        formData.set('IMOVEL_VALOR', simulacaoHid.IMOVEL_VALOR);
        formData.set('RENDA', simulacaoHid.RENDA);
        formData.set('FGTS', simulacaoHid.FGTS);
        formData.set('recursos', simulacaoHid.RECURSOS);
        formData.set('OUTRO_RENDA', simulacaoHid.OUTRO_RENDA);
        formData.set('OUTRO_FGTS', simulacaoHid.OUTRO_FGTS);
        formData.set('OUTRO_RECURSOS', simulacaoHid.OUTRO_RECURSOS);

        formData.append('brand', brand);
        formData.append(
          'logo_link',
          `https://www.${brand}.com/logotext512.png`,
        );

        await sendMail(brand, formData);
      }

      const { error, error_message } = response;

      if (!error) {
        if (updateForm) {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Seus dados foram atualizados com sucesso.',
          });
          setLoading(false);
          upd();
          return;
        }

        if (useValidation) {
          navigate('/thanks');
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua simulação foi enviada com sucesso.',
          });
          setLoading(false);
          upd();
          return;
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error_message,
        });
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const reqField = () => {
    const color = theme === 'light' ? 'cRed' : 'cDarkRed';
    return <span className={`fs10 ${color}`}>*</span>;
  };

  const validate = (e, isValid, isNumber = false) => {
    const feedbackMessages = {
      NOME: 'Informe seu nome completo',
      EMAIL: 'Informe seu e-mail corretamente',
      WHATSAPP: 'Informe um número de WhatsApp válido',
      CPF: 'Informe um CPF válido',
      NASCIMENTO: 'Informe a data de nascimento corretamente',
      IMOVEL_VALOR: 'Informe o valor do imóvel',
      IMOVEL_CIDADE: 'Informe a cidade do imóvel',
      IMOVEL_DESCRICAO: 'Informe o tipo de financiamento',
      RENDA: 'Informe a renda',
      FGTS: 'Informe o saldo aproximado de FGTS que pretende utilizar',
      RECURSOS: 'Informe o saldo de outros recursos próprios',
      OUTRO_NOME: 'Informe o nome completo',
      OUTRO_CPF: 'Informe um CPF válido',
      OUTRO_NASCIMENTO: 'Informe a data de nascimento corretamente',
      OUTRO_WHATSAPP: 'Informe um número de WhatsApp válido',
      OUTRO_RENDA: 'Informe a renda',
      OUTRO_FGTS: 'Informe o saldo aproximado de FGTS que pretende utilizar',
      OUTRO_RECURSOS: 'Informe o saldo de outros recursos próprios',
    };

    const inputElement = document.getElementById(e.target.id);
    const feedbackElement = document.getElementById(`fdbk_${e.target.id}`);
    const boxElement = document.getElementById(`box_${e.target.id}`);

    if (useValidation) {
      if (isValid) {
        inputElement.classList.remove('is-invalid');
        feedbackElement.innerText = '';
        boxElement.classList.remove('invalid-box');
      } else {
        if (e.type === 'blur' || e.type === 'accept') {
          inputElement.classList.add('is-invalid');
          feedbackElement.innerText = feedbackMessages[e.target.id];
          boxElement.classList.add('invalid-box');
        }
      }
    }
    updateState(e, isNumber);
  };

  const colorSet = 'info';
  const buttonColor = `outline-${colorSet} cWhite`;
  const buttonColorActive = 'outline-warning active';

  const colorChecked = 'cYellow fw600';

  const estadoCivilButtons = () => {
    const estadoCivil = [
      'Solteiro(a)',
      'Casado(a)',
      'Divorciado(a)',
      'Viúvo(a)',
      'União Estável',
    ];

    return estadoCivil.map((item) => (
      <Button
        key={item}
        size='sm'
        variant={
          simulacao.ESTADO_CIVIL === item ? buttonColorActive : buttonColor
        }
        className={`m-2 wpMin100 ${
          simulacao.ESTADO_CIVIL === item ? 'active' : ''
        }`}
        onClick={() => {
          const COMPOR = item === 'Casado(a)' ? 1 : 0;
          document
            .getElementById('DIV_ESTADO_CIVIL')
            .classList.remove('invalid-box');
          const RELACIONAMENTO = item === 'Casado(a)' ? 'Cônjuge' : '';
          setSimulacao({
            ...simulacao,
            ESTADO_CIVIL: item,
            COMPOR,
            RELACIONAMENTO,
          });
        }}
      >
        {item}
      </Button>
    ));
  };

  useEffect(() => {
    if (full && simulacao.COMPOR && simulacao.ESTADO_CIVIL === 'Casado(a)') {
      document.getElementById('DIV_RELACIONAMENTO').classList.add('divHide');
    }
    // eslint-disable-next-line
  }, [simulacao.COMPOR, simulacao.ESTADO_CIVIL]);

  const comprovarRendaButtons = (outro = false) => {
    const comprovarRenda = [
      'Holerite',
      'Extrato bancário',
      'Declaração de Imposto de Renda',
      'Pró Labore',
      'Não tenho como comprovar',
    ];

    const param = outro ? 'OUTRO_COMPROVAR' : 'COMPROVAR';

    return comprovarRenda.map((item) => (
      <Button
        key={item}
        variant={
          simulacao[param] && simulacao[param].includes(item)
            ? buttonColorActive
            : buttonColor
        }
        size='sm'
        className={`m-1 ${
          simulacao[param] && simulacao[param].includes(item) ? 'active' : ''
        }`}
        onClick={() => {
          let comprovar = simulacao[param];
          if (comprovar.includes(item)) {
            comprovar = comprovar.filter((i) => i !== item);
          } else {
            comprovar.push(item);
          }
          if (comprovar.length === 0) {
            document
              .getElementById(`DIV_${param}`)
              .classList.add('invalid-box');
          } else {
            document
              .getElementById(`DIV_${param}`)
              .classList.remove('invalid-box');
          }

          setSimulacao({
            ...simulacao,
            [param]: comprovar,
          });
        }}
      >
        {item}
      </Button>
    ));
  };

  const imovelDescricaoButtons = () => {
    return operations.map((operation) => (
      <Button
        key={`${operation.C}_${operation.OPERACAO}`}
        variant={
          simulacao.IMOVEL_CODIGO === operation.C
            ? buttonColorActive
            : buttonColor
        }
        size='sm'
        className={`m-1 ${
          simulacao.IMOVEL_CODIGO === operation.C ? 'active' : ''
        }`}
        onClick={() => {
          setSimulacao({
            ...simulacao,
            IMOVEL_CODIGO: operation.C,
            IMOVEL_DESCRICAO: operation.OPERACAO,
          });
          document
            .getElementById('DIV_IMOVEL_DESCRICAO')
            .classList.remove('invalid-box');
        }}
      >
        {operation.OPERACAO}
      </Button>
    ));
  };

  const relacionamentoButtons = () => {
    const relacionamento = [
      'Cônjuge',
      'Companheiro(a)',
      'Pai/Mãe',
      'Filho(a)',
      'Irmão/Irmã',
      'Outro',
    ];

    return relacionamento.map((item) => (
      <Button
        key={item}
        variant={
          simulacao.RELACIONAMENTO === item ? buttonColorActive : buttonColor
        }
        size='sm'
        className={`m-1 ${simulacao.RELACIONAMENTO === item ? 'active' : ''}`}
        onClick={() => {
          setSimulacao({
            ...simulacao,
            RELACIONAMENTO: item,
          });
          document
            .getElementById('DIV_RELACIONAMENTO')
            .classList.remove('invalid-box');
        }}
      >
        {item}
      </Button>
    ));
  };

  if (loading) return loading_dots();

  const groupFields = {
    group: 'bgOverlayDarkSoft p-3 mt-4',
    label: 'cWhite fw500 fs10 w100 mb-0',
  };

  return (
    <div className={`wrapper ${useBlueBg ? 'bgGradBlue' : ''}`}>
      <div className='simulador'>
        <div className='mw600'>
          <Form
            onSubmit={handleSubmit}
            autoComplete='on'
            data-bs-theme={theme}
          >
            <p className='border-top border-2 border-warning pt-3  mt-5 mb-3 fs14 fw600 cYellow ffSoft'>
              Sobre Você
            </p>

            <FormGroup
              groupClass={groupFields.group}
              labelClass={groupFields.label}
              id='NOME'
              label='Nome completo'
              addFormControl={true}
              formControlProps={{
                type: 'text',
                required: useValidation ? true : false,
                value: simulacao.NOME,
                onChange: (e) => {
                  validate(e, is_compound_name(e.target.value));
                },
                onBlur: (e) => {
                  validate(e, is_compound_name(e.target.value));
                },
              }}
            />

            <FormGroup
              groupClass={groupFields.group}
              labelClass={groupFields.label}
              id='EMAIL'
              label='E-mail'
              addFormControl={true}
              formControlProps={{
                type: 'email',
                required: useValidation ? true : false,
                value: simulacao.EMAIL,
                onChange: (e) => {
                  validate(e, is_valid_email(e.target.value));
                },
                onBlur: (e) => {
                  validate(e, is_valid_email(e.target.value));
                },
              }}
            />

            <FormGroup
              groupClass={groupFields.group}
              labelClass={groupFields.label}
              id='WHATSAPP'
              label='WhatsApp'
              addFormControl={true}
              formControlProps={{
                type: 'text',
                as: IMaskInput,
                mask: '(00) 00000-0000',
                required: useValidation ? true : false,
                value: simulacao.WHATSAPP,
                onChange: (e) => {
                  validate(e, is_valid_phone(e.target.value));
                },
                onBlur: (e) => {
                  validate(e, is_valid_phone(e.target.value));
                },
              }}
            />

            <FormGroup
              groupClass={groupFields.group}
              labelClass={groupFields.label}
              id='CPF'
              label='CPF'
              addFormControl={true}
              formControlProps={{
                type: 'text',
                as: IMaskInput,
                mask: '000.000.000-00',
                required: useValidation ? true : false,
                value: simulacao.CPF,
                onChange: (e) => {
                  validate(e, is_valid_cpf(e.target.value));
                },
                onBlur: (e) => {
                  validate(e, is_valid_cpf(e.target.value));
                },
              }}
            />

            <FormGroup
              groupClass={groupFields.group}
              labelClass={groupFields.label}
              id='NASCIMENTO'
              label='Data de nascimento'
              addFormControl={true}
              formControlProps={{
                type: 'text',
                as: IMaskInput,
                mask: '00/00/0000',
                required: useValidation ? true : false,
                value: simulacao.NASCIMENTO,
                onChange: (e) => {
                  validate(e, is_valid_nascimento(e.target.value));
                },
                onBlur: (e) => {
                  validate(e, is_valid_nascimento(e.target.value));
                },
              }}
            />

            <div
              className={groupFields.group}
              id='DIV_ESTADO_CIVIL'
            >
              <span className={groupFields.label}>
                Estado Civil {reqField()}
              </span>
              <div className='divButtons'>{estadoCivilButtons()}</div>
            </div>

            <p className='border-top border-2 border-warning pt-3  mt-5 mb-3 fs14 fw600 cYellow ffSoft'>
              Sobre o seu futuro imóvel
            </p>

            <FormGroup
              groupClass={groupFields.group}
              labelClass={groupFields.label}
              id='IMOVEL_VALOR'
              label='Valor do imóvel'
              addFormControl={true}
              formControlProps={{
                type: 'text',
                required: useValidation ? true : false,
                value: simulacao.IMOVEL_VALOR,
                onChange: (e) => {
                  validate(e, true, true);
                },
                onKeyUp: (e) => {
                  validate(e, true, true);
                },
              }}
            />

            {cities.length > 0 ? (
              <Form.Group
                className={groupFields.group}
                id='BOX_IMOVEL_CIDADE'
              >
                <Form.Label
                  htmlFor='IMOVEL_CIDADE'
                  className={groupFields.label}
                >
                  Cidade do imóvel {reqField()}
                </Form.Label>
                <Form.Select
                  id='IMOVEL_CIDADE'
                  name='imovel_cidade'
                  required
                  value={simulacao.IMOVEL_CIDADE}
                  onChange={(e) => {
                    validate(e, not_blank(e.target.value));
                  }}
                >
                  <option value=''>Selecione</option>
                  {cities.map((city) => (
                    <option
                      key={city.codigo_cef}
                      value={city.nome}
                    >
                      {city.nome}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback
                  type='invalid'
                  className='mt-0 fs10'
                  id='FDBK_IMOVEL_CIDADE'
                >
                  Informe a cidade do imóvel
                </Form.Control.Feedback>
              </Form.Group>
            ) : (
              ''
            )}

            <div
              className={groupFields.group}
              id='DIV_IMOVEL_DESCRICAO'
            >
              <span className={groupFields.label}>
                Tipo de Financiamento {reqField()}
              </span>
              <div className='divButtons'>
                {operations.length > 0 && imovelDescricaoButtons()}
              </div>
            </div>

            <Form.Group className={groupFields.group}>
              <Form.Label
                htmlFor='corretor'
                className={groupFields.label}
              >
                Corretor
              </Form.Label>
              <Form.Control
                type='text'
                id='CORRETOR'
                name='corretor'
                value={simulacao.CORRETOR}
                disabled={corretor ? true : false}
                onChange={(e) =>
                  updateSimulacaoState(e.target.id, e.target.value)
                }
              />
            </Form.Group>
            <Form.Group className={groupFields.group}>
              <Form.Label
                htmlFor='imobiliaria'
                className={groupFields.label}
              >
                Imobiliária
              </Form.Label>
              <Form.Control
                type='text'
                id='IMOBILIARIA'
                name='imobiliaria'
                value={simulacao.IMOBILIARIA}
                disabled={corretor ? true : false}
                onChange={(e) =>
                  updateSimulacaoState(e.target.id, e.target.value)
                }
              />
            </Form.Group>

            <p className='border-top border-2 border-warning pt-3  mt-5 mb-3 fs14 fw600 cYellow ffSoft'>
              Perfil de Financiamento
            </p>

            <FormGroup
              groupClass={groupFields.group}
              labelClass={groupFields.label}
              id='RENDA'
              label='Renda bruta mensal'
              addFormControl={true}
              formControlProps={{
                type: 'text',
                required: useValidation ? true : false,
                value: simulacao.RENDA,
                onChange: (e) => {
                  validate(e, true, true);
                },
                onKeyUp: (e) => {
                  validate(e, true, true);
                },
              }}
            />

            <div
              className={groupFields.group}
              id='DIV_COMPROVAR'
            >
              <span className={groupFields.label}>
                Comprovação de renda {reqField()}
              </span>
              <div className='divButtons'>{comprovarRendaButtons()}</div>
            </div>

            <FormGroup
              groupClass={groupFields.group}
              labelClass={groupFields.label}
              id='FGTS'
              label='Informe o saldo aproximado de FGTS que pretende utilizar'
              addFormControl={true}
              includeDenyField={true}
              denyFieldText='Não utilizarei saldo de FGTS'
              formControlProps={{
                type: 'text',
                required: useValidation ? true : false,
                value: simulacao.FGTS,
                onChange: (e) => {
                  validate(e, true, true);
                },
                onKeyUp: (e) => {
                  validate(e, true, true);
                },
              }}
            />

            <FormGroup
              groupClass={groupFields.group}
              labelClass={groupFields.label}
              id='RECURSOS'
              label='Informe o saldo de outros recursos próprios (exceto FGTS)'
              addFormControl={true}
              includeDenyField={true}
              denyFieldText='Não utilizarei outros recursos próprios'
              formControlProps={{
                type: 'text',
                required: useValidation ? true : false,
                value: simulacao.RECURSOS,
                onChange: (e) => {
                  validate(e, true, true);
                },
                onKeyUp: (e) => {
                  validate(e, true, true);
                },
              }}
            />

            <Form.Group className={groupFields.group}>
              <Form.Check
                type='checkbox'
                id='COMPOR'
                required={
                  useValidation &&
                  simulacao.ESTADO_CIVIL &&
                  simulacao.ESTADO_CIVIL.includes('Casado')
                    ? 1
                    : 0
                }
                name='compor'
                checked={
                  (simulacao.ESTADO_CIVIL &&
                    simulacao.ESTADO_CIVIL.includes('Casado')) ||
                  simulacao.compor
                }
                className='mb-0 cWhite fw500'
                label={
                  simulacao.ESTADO_CIVIL &&
                  simulacao.ESTADO_CIVIL.includes('Casado') ? (
                    <>
                      Incluir dados do cônjuge para compor renda
                      <span
                        className={`fs10 ${
                          theme === 'light' ? 'cRed' : 'cDarkRed'
                        } `}
                      >
                        {' '}
                        * (obrigatório para casados)
                      </span>
                    </>
                  ) : (
                    'Deseja compor renda com mais uma pessoa?'
                  )
                }
                onChange={(e) =>
                  updateSimulacaoState(e.target.id, e.target.checked ? 1 : 0)
                }
              />
            </Form.Group>

            {full &&
            (simulacao.COMPOR ||
              (simulacao.ESTADO_CIVIL &&
                simulacao.ESTADO_CIVIL.includes('Casado'))) ? (
              <div>
                <p className='border-top border-2 border-warning pt-3  mt-5 mb-3 fs14 fw600 cYellow ffSoft'>
                  Sobre{' '}
                  {simulacao.ESTADO_CIVIL.includes('Casado')
                    ? 'seu cônjuge'
                    : 'a pessoa que irá compor renda com você'}
                </p>

                {full ? (
                  <>
                    {simulacao.ESTADO_CIVIL &&
                    simulacao.ESTADO_CIVIL.includes('Casado') ? (
                      <p className='mt-3 mb-2 fs10'>
                        Como você informou ser casado(a), é necessário informar
                        os dados abaixo, mesmo que{' '}
                        {simulacao.ESTADO_CIVIL.includes('Casado')
                          ? 'seu cônjuge'
                          : 'a pessoa que irá compor renda com você'}{' '}
                        não tenha renda.
                      </p>
                    ) : (
                      ''
                    )}

                    <div id='DIV_RELACIONAMENTO'>
                      {simulacao.COMPOR ||
                      (simulacao.ESTADO_CIVIL &&
                        simulacao.ESTADO_CIVIL.includes('Casado')) ? (
                        <Form.Group className={groupFields.group}>
                          <Form.Label className={groupFields.label}>
                            Relacionamento com a pessoa que irá compor renda{' '}
                            {reqField()}
                          </Form.Label>

                          <div className='divButtons'>
                            {relacionamentoButtons()}
                          </div>
                        </Form.Group>
                      ) : (
                        ''
                      )}
                    </div>
                  </>
                ) : (
                  ''
                )}

                <FormGroup
                  groupClass={groupFields.group}
                  labelClass={groupFields.label}
                  id='OUTRO_NOME'
                  label='Nome completo'
                  addFormControl={true}
                  formControlProps={{
                    type: 'text',
                    required: useValidation ? true : false,
                    value: simulacao.OUTRO_NOME,
                    onChange: (e) => {
                      validate(e, is_compound_name(e.target.value));
                    },
                    onBlur: (e) => {
                      validate(e, is_compound_name(e.target.value));
                    },
                  }}
                />

                <FormGroup
                  groupClass={groupFields.group}
                  labelClass={groupFields.label}
                  id='OUTRO_CPF'
                  label='CPF'
                  addFormControl={true}
                  formControlProps={{
                    type: 'text',
                    as: IMaskInput,
                    mask: '000.000.000-00',
                    required: useValidation ? true : false,
                    value: simulacao.OUTRO_CPF,
                    onChange: (e) => {
                      validate(e, is_valid_cpf(e.target.value));
                    },
                    onBlur: (e) => {
                      validate(e, is_valid_cpf(e.target.value));
                    },
                  }}
                />

                <FormGroup
                  groupClass={groupFields.group}
                  labelClass={groupFields.label}
                  id='OUTRO_WHATSAPP'
                  label='WhatsApp'
                  addFormControl={true}
                  formControlProps={{
                    type: 'text',
                    as: IMaskInput,
                    mask: '(00) 00000-0000',
                    required: useValidation ? true : false,
                    value: simulacao.OUTRO_WHATSAPP,
                    onChange: (e) => {
                      validate(e, is_valid_phone(e.target.value));
                    },
                    onBlur: (e) => {
                      validate(e, is_valid_phone(e.target.value));
                    },
                  }}
                />

                <FormGroup
                  groupClass={groupFields.group}
                  labelClass={groupFields.label}
                  id='OUTRO_NASCIMENTO'
                  label='Data de nascimento'
                  addFormControl={true}
                  formControlProps={{
                    type: 'text',
                    as: IMaskInput,
                    mask: '00/00/0000',
                    required: useValidation ? true : false,
                    value: simulacao.OUTRO_NASCIMENTO,
                    onChange: (e) => {
                      validate(e, is_valid_nascimento(e.target.value));
                    },
                    onBlur: (e) => {
                      validate(e, is_valid_nascimento(e.target.value));
                    },
                  }}
                />

                <FormGroup
                  groupClass={groupFields.group}
                  labelClass={groupFields.label}
                  id='OUTRO_RENDA'
                  label='Renda bruta mensal'
                  addFormControl={true}
                  includeDenyField={true}
                  denyFieldText='Não possui renda'
                  formControlProps={{
                    type: 'text',
                    required: useValidation ? true : false,
                    value: simulacao.OUTRO_RENDA,
                    onChange: (e) => {
                      validate(e, true, true);
                    },
                    onKeyUp: (e) => {
                      validate(e, true, true);
                    },
                  }}
                />

                <FormGroup
                  groupClass={groupFields.group}
                  labelClass={groupFields.label}
                  id='OUTRO_FGTS'
                  label='Informe o saldo aproximado de FGTS que pretende utilizar'
                  addFormControl={true}
                  includeDenyField={true}
                  denyFieldText='Não possui saldo de FGTS'
                  formControlProps={{
                    type: 'text',
                    required: useValidation ? true : false,
                    value: simulacao.OUTRO_FGTS,
                    onChange: (e) => {
                      validate(e, true, true);
                    },
                    onKeyUp: (e) => {
                      validate(e, true, true);
                    },
                  }}
                />

                <FormGroup
                  groupClass={groupFields.group}
                  labelClass={groupFields.label}
                  id='OUTRO_RECURSOS'
                  label='Informe o saldo de outros recursos próprios (exceto FGTS)'
                  addFormControl={true}
                  includeDenyField={true}
                  denyFieldText='Não possui outros recursos próprios'
                  formControlProps={{
                    type: 'text',
                    required: useValidation ? true : false,
                    value: simulacao.OUTRO_RECURSOS,
                    onChange: (e) => {
                      validate(e, true, true);
                    },
                    onKeyUp: (e) => {
                      validate(e, true, true);
                    },
                  }}
                />

                <div
                  className={groupFields.group}
                  id='DIV_OUTRO_COMPROVAR'
                >
                  <span className={groupFields.label}>
                    Comprovação de renda dessa pessoa {reqField()}
                  </span>
                  <div className='divButtons'>
                    {comprovarRendaButtons(true)}
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            <p className='border-top border-2 border-warning pt-3  mt-5 mb-3 fs14 fw600 cYellow ffSoft'>
              {simulacao.COMPOR ||
              (simulacao.ESTADO_CIVIL &&
                simulacao.ESTADO_CIVIL.includes('Casado'))
                ? 'Marque abaixo se as opções se aplicam a você ou a quem irá compor renda com você'
                : 'Marque abaixo se as opções se aplicam a você'}
            </p>

            <Form.Group className={groupFields.group}>
              <p className={groupFields.label}>
                Possui Imóvel Registrado {reqField()}
              </p>
              <div className='mt-0 divYesNo'>
                <Form.Check
                  required={useValidation ? true : false}
                  type='radio'
                  id='POSSUI_IMOVEL_SIM'
                  className={`radioYesNo  ${
                    simulacao.POSSUI_IMOVEL === 1 ? colorChecked : ''
                  } `}
                  name='POSSUI_IMOVEL'
                  checked={simulacao.POSSUI_IMOVEL === 1}
                  label='Sim'
                  value={1}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
                <Form.Check
                  type='radio'
                  id='POSSUI_IMOVEL_NAO'
                  checked={simulacao.POSSUI_IMOVEL === 0}
                  className={`radioYesNo ${
                    simulacao.POSSUI_IMOVEL === 0 ? colorChecked : ''
                  } `}
                  name='POSSUI_IMOVEL'
                  label='Não'
                  value={0}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
              </div>
              {simulacao.POSSUI_IMOVEL === 1 ? (
                <Form.Group className='my-2'>
                  <Form.Label
                    htmlFor='POSSUI_IMOVEL_CIDADE'
                    className={groupFields.label}
                  >
                    Cidade do imóvel {reqField()}
                  </Form.Label>
                  <Form.Control
                    type='text'
                    id='POSSUI_IMOVEL_CIDADE'
                    name='POSSUI_IMOVEL_CIDADE'
                    required={useValidation ? true : false}
                    value={simulacao.POSSUI_IMOVEL_CIDADE}
                    onChange={(e) =>
                      updateSimulacaoState(e.target.id, e.target.value)
                    }
                  />
                </Form.Group>
              ) : (
                ''
              )}
            </Form.Group>

            <Form.Group className={groupFields.group}>
              <p className={groupFields.label}>
                É servidor público? {reqField()}
              </p>
              <div className='mt-0 divYesNo'>
                <Form.Check
                  required={useValidation ? true : false}
                  type='radio'
                  id='SERVIDOR_PUBLICO_SIM'
                  name='SERVIDOR_PUBLICO'
                  className={`radioYesNo ${
                    simulacao.SERVIDOR_PUBLICO === 1 ? colorChecked : ''
                  } `}
                  label='Sim'
                  checked={simulacao.SERVIDOR_PUBLICO === 1}
                  value={1}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />{' '}
                <Form.Check
                  type='radio'
                  id='SERVIDOR_PUBLICO_NAO'
                  name='SERVIDOR_PUBLICO'
                  className={`radioYesNo ${
                    simulacao.SERVIDOR_PUBLICO === 0 ? colorChecked : ''
                  } `}
                  label='Não'
                  checked={simulacao.SERVIDOR_PUBLICO === 0}
                  value={0}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
              </div>
            </Form.Group>

            <Form.Group className={groupFields.group}>
              <p className={groupFields.label}>
                Possui 3 anos de trabalho registrado, considerando todos os
                empregos que já teve? (mesmo se atualmente não estiver
                registrado) {reqField()}
              </p>
              <div className='mt-0 divYesNo'>
                <Form.Check
                  required={useValidation ? true : false}
                  type='radio'
                  id='ANOS_FGTS_SIM'
                  className={`radioYesNo ${
                    simulacao.ANOS_FGTS === 1 ? colorChecked : ''
                  } `}
                  name='ANOS_FGTS'
                  checked={simulacao.ANOS_FGTS === 1}
                  label='Sim'
                  value={1}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
                <Form.Check
                  type='radio'
                  id='ANOS_FGTS_NAO'
                  checked={simulacao.ANOS_FGTS === 0}
                  className={`radioYesNo ${
                    simulacao.ANOS_FGTS === 0 ? colorChecked : ''
                  } `}
                  name='ANOS_FGTS'
                  label='Não'
                  value={0}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
              </div>
            </Form.Group>

            <Form.Group className={groupFields.group}>
              <p className={groupFields.label}>
                Possui dependentes (filhos ou cônjuge)? {reqField()}
              </p>
              <div className='mt-0 divYesNo'>
                <Form.Check
                  required={useValidation ? true : false}
                  type='radio'
                  id='DEPENDENTES_SIM'
                  className={`radioYesNo ${
                    simulacao.DEPENDENTES === 1 ? colorChecked : ''
                  } `}
                  name='DEPENDENTES'
                  checked={simulacao.DEPENDENTES === 1}
                  label='Sim'
                  value={1}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
                <Form.Check
                  type='radio'
                  id='DEPENDENTES_NAO'
                  checked={simulacao.DEPENDENTES === 0}
                  className={`radioYesNo ${
                    simulacao.DEPENDENTES === 0 ? colorChecked : ''
                  } `}
                  name='DEPENDENTES'
                  label='Não'
                  value={0}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
              </div>
            </Form.Group>

            <Form.Group className={groupFields.group}>
              <p className={groupFields.label}>
                Atualmente registrado CLT? {reqField()}
              </p>
              <div className='mt-0 divYesNo'>
                <Form.Check
                  required={useValidation ? true : false}
                  type='radio'
                  id='CLT_SIM'
                  className={`radioYesNo ${
                    simulacao.CLT === 1 ? colorChecked : ''
                  } `}
                  name='CLT'
                  checked={simulacao.CLT === 1}
                  label='Sim'
                  value={1}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
                <Form.Check
                  type='radio'
                  id='CLT_NAO'
                  checked={simulacao.CLT === 0}
                  className={`radioYesNo ${
                    simulacao.CLT === 0 ? colorChecked : ''
                  } `}
                  name='CLT'
                  label='Não'
                  value={0}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
              </div>
            </Form.Group>

            <Form.Group className={groupFields.group}>
              <p className={groupFields.label}>
                Foi beneficiado por algum programa habitacional? {reqField()}
              </p>
              <div className='mt-0 divYesNo'>
                <Form.Check
                  required={useValidation ? true : false}
                  type='radio'
                  id='BENEFICIADO_SIM'
                  className={`radioYesNo ${
                    simulacao.BENEFICIADO === 1 ? colorChecked : ''
                  } `}
                  name='BENEFICIADO'
                  checked={simulacao.BENEFICIADO === 1}
                  label='Sim'
                  value={1}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
                <Form.Check
                  type='radio'
                  id='BENEFICIADO_NAO'
                  checked={simulacao.BENEFICIADO === 0}
                  className={`radioYesNo ${
                    simulacao.BENEFICIADO === 0 ? colorChecked : ''
                  } `}
                  name='BENEFICIADO'
                  label='Não'
                  value={0}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
              </div>
            </Form.Group>

            <Form.Group className={groupFields.group}>
              <Form.Label
                htmlFor='INFORMACOES'
                className={groupFields.label}
              >
                Informações adicionais
              </Form.Label>
              <Form.Control
                as='textarea'
                id='INFORMACOES'
                name='INFORMACOES'
                rows={3}
                value={simulacao.INFORMACOES}
                onChange={(e) =>
                  updateSimulacaoState(e.target.id, e.target.value)
                }
              />
            </Form.Group>

            <Form.Group className={groupFields.group}>
              <Form.Check
                required={useValidation ? true : false}
                id='COMPARTILHAR_CORRETOR'
                name='COMPARTILHAR_CORRETOR'
                checked={simulacao.COMPARTILHAR_CORRETOR}
                className='m-0'
                label={
                  <span className={groupFields.label}>
                    Concordo em compartilhar as informações necessárias com o
                    meu corretor
                    <br />
                    <span className='d-flex flex-column mt-1 fs10'>
                      <span className='fw600 ffSoft cWhiteLow'>
                        Por que isso é essencial?
                      </span>
                      <span className='mt-1 faJustify'>
                        O seu corretor de imóveis é o profissional que apoiará
                        no envio de documentos (inclusive a documentação do
                        vendedor), acompanhando vistoria de engenharia e outros
                        procedimentos. Se você não tem um corretor, desmarque
                        essa opção.
                      </span>
                      <span className='mt-1 cWhiteLow'>
                        Se você mudar de ideia, basta nos informar.
                      </span>
                    </span>
                  </span>
                }
                onChange={(e) =>
                  updateSimulacaoState(e.target.id, e.target.checked ? 1 : 0)
                }
              />
            </Form.Group>
            <Form.Group className={groupFields.group}>
              <Form.Check
                required={useValidation ? true : false}
                id='TERMOS'
                name='TERMOS'
                checked={simulacao.TERMOS}
                className='m-0'
                label={
                  <span className={groupFields.label}>
                    Concordo com os{' '}
                    <a
                      href='https://www.hamanncorp.com/privacidade'
                      target='_blank'
                      rel='noreferrer'
                      aria-label='Termos e Condições'
                      title='Termos e Condições'
                    >
                      termos e condições
                    </a>{' '}
                    {reqField()}
                  </span>
                }
                feedback='É necessário concordar com os termos antes de prosseguir.'
                feedbackType='invalid'
                onChange={(e) =>
                  updateSimulacaoState(e.target.id, e.target.checked ? 1 : 0)
                }
              />
            </Form.Group>
            {loading ? (
              loading_dots()
            ) : (
              <div className='text-center mb-3 mt-5'>
                <Button
                  type='submit'
                  variant='warning'
                  className='px-5 py-3 fw600 ffSoft'
                >
                  {updateForm
                    ? 'Atualizar simulação'
                    : 'Enviar simulação agora'}
                </Button>
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
}
