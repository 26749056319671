import { useState } from 'react';
import { AdminCustom, AdminFiliais, AdminUsers } from '../../config/imports';

export default function Admin() {

  const [section, setSection] = useState('users');

  const sections = () => {
    switch (section) {
      case 'users':
        return <AdminUsers />;
      case 'filiais':
        return <AdminFiliais />;
      case 'custom':
        return <AdminCustom />;
      default:
        return <AdminUsers />;
    }
  };

  return (
    <div className='container my-5'>
      <h4 className='ffSoft textContrastColor mb-3'>Administração</h4>
      <p className='mt-2 mb-5'>
        <span className='me-2 ffSoft'>Selecione a seção</span>
        <select
          className='filterTable'
          onChange={(event) => setSection(event.target.value)}
        >
          <option value='users'>Usuários</option>
          <option value='filiais'>Filiais</option>
          <option value='custom'>Personalizar</option>
        </select>
      </p>
      {sections()}

    </div>
  );
}
