import React, { useContext, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { IMaskInput } from 'react-imask';
import '../../assets/css/app/financiamentos.css';
import {
  CONF,
  SCR,
  PKG,
  RendaGraph,
  GlobalContext,
} from '../../config/imports';

export default function Financiamentos({
  vr_renda = 1000,
  vr_imovel = 100000,
  vr_entrada = 20000,
  dt_nascimento = '01/01/1980',
  fgts_3anos = false,
  beneficiado_mcmv = false,
  possui_imovel_reg = false,
  ismodal = false,
  full = false,
  datahtml2canvasignore = true,
}) {
  const {
    calcularMeses,
    classTr,
    tooltipIcons,
    tooltipParcela,
    tooltip_faixa,
    evalAnalises,
  } = SCR.Financ;
  const { mascaraMoeda, numberFormat, numberReFormat } = SCR.Helpers;

  const { theme } = useContext(PKG.PackageContext);
  const { a } = useContext(GlobalContext);

  const [showGraph, setShowGraph] = useState(false);

  const { analises, variacoes } = a;

  const [useVariacoes, setUseVariacoes] = useState(variacoes);
  const [linhas, setLinhas] = useState(analises);

  // const linhas = analises;
  // const useVariacoes = variacoes;

  const [moneyCurrency, setMoneyCurrency] = useState({
    imovel: mascaraMoeda(parseFloat(vr_imovel).toFixed(2)),
    entrada: mascaraMoeda(parseFloat(vr_entrada).toFixed(2)),
    renda: mascaraMoeda(parseFloat(vr_renda).toFixed(2)),
  });
  const [moneyValues, setMoneyValues] = useState({
    imovel: vr_imovel,
    entrada: vr_entrada,
    renda: vr_renda,
  });

  const parcelaMaxInicial = moneyValues.renda * 0.3;

  const nasc = new Date(dt_nascimento).toLocaleDateString('pt-BR');
  const max = calcularMeses(nasc);

  const [parcelas, setParcelas] = useState(max);
  const [nascimento, setNascimento] = useState(nasc);
  const [parcelaMax, setParcelaMax] = useState(parcelaMaxInicial);
  const [fgts, setFgts] = useState(fgts_3anos);
  const [beneficiado, setBeneficiado] = useState(beneficiado_mcmv);
  const [possuiImovel, setPossuiImovel] = useState(possui_imovel_reg);
  const [proCotista, setProCotista] = useState(false);

  const [graphMaxRenda, setGraphMaxRenda] = useState(10000);
  const [graphMaxImovel, setGraphMaxImovel] = useState(700000);

  const handleMoney = (e) => {
    const { name } = e.target;
    const valueReformatted = mascaraMoeda(e.target.value);
    const valueNumber = numberReFormat(valueReformatted);
    if (name === 'renda') {
      let max = valueNumber * 0.3;
      setParcelaMax(max);
      setMoneyCurrency({ ...moneyCurrency, renda: valueReformatted });
      setMoneyValues({ ...moneyValues, renda: valueNumber });
    } else if (name === 'imovel') {
      let entrada = valueNumber * 0.2;
      setMoneyCurrency({
        ...moneyCurrency,
        entrada: mascaraMoeda(entrada.toFixed(2)),
        imovel: valueReformatted,
      });
      setMoneyValues({ ...moneyValues, entrada, imovel: valueNumber });
    } else {
      setMoneyCurrency({ ...moneyCurrency, [name]: valueReformatted });
      setMoneyValues({ ...moneyValues, [name]: valueNumber });
    }
  };

  const handleRenda = (valor) => {
    const valueReformatted = mascaraMoeda(valor);
    setMoneyCurrency({ ...moneyCurrency, renda: valueReformatted });
    setMoneyValues({ ...moneyValues, renda: valor });
    let max = valor * 0.3;
    setParcelaMax(max);
  };

  const handleImovel = (valor) => {
    const valueReformatted = mascaraMoeda(valor);
    setMoneyCurrency({ ...moneyCurrency, imovel: valueReformatted });
    setMoneyValues({ ...moneyValues, imovel: valor });
    let entrada = valor * 0.2;
    setMoneyCurrency({
      ...moneyCurrency,
      imovel: valueReformatted,
      entrada: mascaraMoeda(entrada.toFixed(2)),
    });
    setMoneyValues({ ...moneyValues, entrada, imovel: valor });
  };

  const handleClick = () => {
    const { imovel, entrada } = moneyValues;
    const financiado = imovel - entrada;
    let results = [];
    // let resultsRenda = [];
    // let resultsImovel = [];
    let evaluation = evalAnalises(
      moneyValues.renda,
      moneyValues.imovel,
      linhas,
      useVariacoes,
    );

    const aliquotaSeguro = useVariacoes.find((v) => v.NOME === 'Seguro').VALUE;
    const maxParcelasSac = useVariacoes.find(
      (v) => v.NOME === 'Máx. Parcelas SAC',
    ).VALUE;
    const maxParcelasPrice = useVariacoes.find(
      (v) => v.NOME === 'Máx. Parcelas Price',
    ).VALUE;
    const taxaAdmin = useVariacoes.find((v) => v.NOME === 'Taxa Admin').VALUE;

    Object.keys(evaluation).forEach((key) => {
      const obj = evaluation[key];
      const evaluate = evaluation[key].evaluate;
      let thisTaxa = evaluation[key].taxa;

      if (
        proCotista &&
        fgts &&
        !beneficiado &&
        !possuiImovel &&
        (key.includes('MCMV') || key.includes('SBPE'))
      ) {
        thisTaxa = evaluation[key].taxaEsp;
      }

      let resultVariacao = evaluation[key].variacao.id
        ? `${evaluation[key].variacao.nome}: ${evaluation[key].variacao.valor} %`
        : '';

      let fin = new SCR.Financiamentos(
        financiado,
        thisTaxa,
        parcelas,
        aliquotaSeguro,
        taxaAdmin,
        'anual',
        maxParcelasSac,
        maxParcelasPrice,
      );

      // let finRenda = new SCR.Financiamentos(

      let result = fin.result();
      const compativel = result.sacInicial <= parcelaMax;
      const compativelPrice = result.price <= parcelaMax;

      results.push({
        tabela: key,
        variacao: resultVariacao,
        compativel,
        compativelPrice,
        evaluate,
        fgts,
        beneficiado,
        possuiImovel,
        tx: parseFloat(thisTaxa),
        ...result,
        obj,
      });
    });

    // console.log(results);
    return (
      <>
        {results.map((result) => {
          return (
            <tr
              key={result.tabela}
              className={classTr(result, beneficiado, possuiImovel)}
              data-toggle='tooltip'
              title={
                classTr(result, beneficiado, possuiImovel) ===
                'bg-table-grey-light'
                  ? `Já foi beneficiado pelo MCMV ou possui imóvel registrado. Não é possível utilizar essa faixa.`
                  : ''
              }
            >
              <td
                className=''
                data-toggle='tooltip'
                title={tooltip_faixa(result, linhas)}
              >
                {result.tabela}
              </td>
              <td className=''>
                <span
                  data-toggle='tooltip'
                  title={tooltipIcons(result, 'renda', linhas, moneyValues)}
                >
                  <PKG.IconsUI
                    info={PKG.ui.misc.money}
                    clsnm={`mx-1 svg14 ${
                      result.evaluate && result.evaluate.renda
                        ? 'cGreen'
                        : 'cGray300'
                    }`}
                  />
                </span>
                <span
                  data-toggle='tooltip'
                  title={tooltipIcons(result, 'imovel', linhas, moneyValues)}
                >
                  <PKG.IconsUI
                    info={PKG.ui.misc.house}
                    clsnm={`mx-1 svg14 ${
                      result.evaluate && result.evaluate.imovel
                        ? 'cGreen'
                        : 'cGray300'
                    }`}
                  />
                </span>
                <span
                  data-toggle='tooltip'
                  title={tooltipIcons(result, 'subsidio', linhas, moneyValues)}
                >
                  <PKG.IconsUI
                    info={PKG.ui.misc.star}
                    clsnm={`mx-1 svg14 ${
                      result.evaluate && result.evaluate.subsidio
                        ? 'cGreen'
                        : 'cGray300'
                    }`}
                  />
                </span>
              </td>
              <td
                className={`divHide600 ${ismodal ? 'divHide' : ''}`}
              >{`${parseFloat(result.tx).toFixed(2)} %`}</td>
              <td className={`divHide600 ${ismodal ? 'divHide' : ''}`}>
                {result.variacao}
              </td>
              <td className=''>{`${parseFloat(result.tx).toFixed(4)} %`}</td>
              <td className={`divHide600 ${ismodal ? 'divHide' : ''}`}>
                {result.encargos}
              </td>
              <td
                data-toggle='tooltip'
                title={tooltipParcela(result, 'SAC', parcelaMax)}
              >
                <span
                  className={
                    result.sacInicial <= parcelaMax ? 'cGreen' : 'cRed'
                  }
                >
                  {result.primeiraParcelaSac}
                </span>
                <br />
                <span className={parcelaMax < result.sacFinal ? 'cRed' : ''}>
                  {result.ultimaParcelaSac}
                </span>
              </td>
              <td
                data-toggle='tooltip'
                title={tooltipParcela(result, 'PRICE', parcelaMax)}
              >
                <span
                  className={result.price <= parcelaMax ? 'cGreen' : 'cRed'}
                >
                  {result.prestacaoPrice}
                </span>
              </td>

              <td className=''>
                <span className='textContrast'>{result.totalJurosSac}</span>
                <br />
                <span>{result.totalPagoSac}</span>
              </td>

              <td className=''>
                <span className='textContrast'>{result.totalJurosPrice}</span>
                <br />
                <span>{result.totalPagoPrice}</span>
              </td>
            </tr>
          );
        })}
      </>
    );
  };

  return (
    <div
      className='container my-5'
      data-html2canvas-ignore={datahtml2canvasignore}
    >
      <header
        data-html2canvas-ignore={true}
        className={`fs10 pt-1 pb-2 divHide ${
          ismodal ? 'divShow' : ''
        } text-center`}
      >
        <img
          src={CONF.images[theme].logotext}
          className='logoHeader'
          alt='logo'
        />
        <h5 className='mt-5'>Calculadora de Financiamento Imobiliário</h5>
        <p className={`fs10 pt-1 pb-2`}>
          Esse cálculo não inclui as taxas e seguros inclusos pelos bancos.
          <br />
          Para um cálculo mais detalhado, preencha o{' '}
          <a
            href='https://simulador.hamanncorp.com'
            target='_blank'
            rel='noreferrer'
            aria-label='formulário de simulação'
          >
            formulário de simulação
          </a>
        </p>
      </header>
      <div className='body'>
        <div className='gorow justify-content-start align-items-center my-2'>
          <span className='fs16 fw500 ffSoft textContrastColor'>
            Simulador de Financiamento
          </span>
          <PKG.IconsUI
            info={PKG.ui.expand.more}
            clsnm='svg20 cLink hovGreen mousePointerHover ms-2'
            click={() => {
              const form_el = document.getElementById('formSimulador');
              form_el.classList.toggle('divHide');
            }}
          />
        </div>

        <form
          action=''
          id='formSimulador'
          data-html2canvas-ignore={true}
          className={`fs12 pt-1 pb-2 faLeft p-3 rounded ${
            ismodal ? 'divHide' : ''
          }`}
        >
          <div className='gocol'>
            <div className='gorow justify-content-between gocol600 wpMax900 mAuto'>
              <div className='gocol border rounded border-success p-3 fs12 m-2 '>
                <h6 className='my-1 pb-2'>Dados do Financiamento</h6>

                <Form.Group className='gocol align-items-start pb-4'>
                  <Form.Label
                    className='fw500 ffSoft m-0 cBlue'
                    htmlFor='imovel'
                  >
                    Valor do Imóvel
                  </Form.Label>

                  <Form.Control
                    type='text'
                    size='sm'
                    className='form-control'
                    name='imovel'
                    id='imovel'
                    value={moneyCurrency.imovel}
                    onChange={(e) => handleMoney(e)}
                  />
                </Form.Group>

                <Form.Group className='gocol align-items-start pb-4'>
                  <Form.Label
                    className='fw500 ffSoft m-0 cBlue'
                    htmlFor='entrada'
                  >
                    Valor da Entrada
                  </Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    className='form-control'
                    name='entrada'
                    id='entrada'
                    value={moneyCurrency.entrada}
                    onChange={(e) => handleMoney(e)}
                  />
                </Form.Group>
                <span
                  id='info-entrada'
                  className='fs10 mb-1'
                  onClick={() => {
                    const value_entrada = Number(
                      (moneyValues.imovel / 5).toFixed(2),
                    );
                    setMoneyCurrency({
                      ...moneyCurrency,
                      entrada: numberFormat(value_entrada),
                    });
                    setMoneyValues({
                      ...moneyValues,
                      entrada: value_entrada,
                    });
                  }}
                >
                  {moneyValues.imovel > 0 ? (
                    <>
                      <span className='cGray300'>
                        A entrada mínima deve ser de 20% do valor do imóvel:{' '}
                        {numberFormat(moneyValues.imovel / 5)}.
                      </span>{' '}
                      <span className='hovGreen mousePointerHover'>
                        Clique aqui para definir esse valor.
                      </span>
                    </>
                  ) : (
                    ''
                  )}
                </span>

                <Form.Group className='gocol align-items-start pb-4'>
                  <Form.Label
                    className='fw500 ffSoft cBlue m-0'
                    htmlFor='renda'
                  >
                    Renda
                  </Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    className='form-control'
                    name='renda'
                    id='renda'
                    value={moneyCurrency.renda}
                    onChange={(e) => handleMoney(e)}
                  />
                </Form.Group>
                <span
                  className='fs10 mb-1'
                  id='info-renda'
                >
                  {moneyValues.renda > 0 ? (
                    <span className='cGray300'>
                      A parcela não poderá exceder 30% da renda. A parcela
                      máxima será de {numberFormat(moneyValues.renda * 0.3)}
                    </span>
                  ) : (
                    ''
                  )}
                </span>

                <Form.Group className='gocol align-items-start pb-4'>
                  <Form.Label
                    className='fw500 ffSoft cBlue m-0'
                    htmlFor='nascimento'
                  >
                    Nascimento
                  </Form.Label>
                  <Form.Control
                    type='text'
                    as={IMaskInput}
                    mask='00/00/0000'
                    id='nascimento'
                    name='nascimento'
                    size='sm'
                    className='form-control'
                    required
                    value={nascimento}
                    onBlur={(e) => {
                      setNascimento(e.target.value);
                      setParcelas(calcularMeses(e.target.value));
                    }}
                  />
                </Form.Group>

                <Form.Group className='gocol align-items-start pb-4'>
                  <Form.Label
                    className='fw500 ffSoft cBlue m-0'
                    htmlFor='parcelas'
                  >
                    nº Máximo de Parcelas
                  </Form.Label>
                  <Form.Control
                    type='number'
                    name='parcelas'
                    size='sm'
                    className='form-control'
                    id='parcelas'
                    value={parcelas}
                    min='12'
                    max='420'
                    maxLength={3}
                    onChange={(e) => setParcelas(e.target.value)}
                  />
                </Form.Group>

                <Form.Check // prettier-ignore
                  type='checkbox'
                  id='check_3anos_fgts'
                  label='Possui 3 anos de FGTS'
                  checked={fgts}
                  onChange={(e) => setFgts(e.target.checked)}
                />
                <Form.Check // prettier-ignore
                  type='checkbox'
                  id='beneficiado'
                  label='Já foi beneficiado pelo MCMV'
                  checked={beneficiado}
                  onChange={(e) => setBeneficiado(e.target.checked)}
                />
                <Form.Check // prettier-ignore
                  type='checkbox'
                  id='possui_imovel'
                  label='Possui imóvel registrado'
                  checked={possuiImovel}
                  onChange={(e) => setPossuiImovel(e.target.checked)}
                />
                <Form.Check // prettier-ignore
                  type='checkbox'
                  id='pro_cotista'
                  label='Verificar ProCotista'
                  checked={proCotista}
                  onChange={(e) => setProCotista(e.target.checked)}
                />
              </div>

              <div className='gocol border rounded border-success p-3 fs12 m-2 '>
                <div className='gorow align-items-center justify-content-between'>
                  <h6 className='m-0 p-2'>Dados Variáveis</h6>
                  <PKG.IconsUI
                    info={PKG.ui.expand.more}
                    clsnm='svg20 cLink  hovGreen mousePointerHover'
                    click={() => {
                      const div_el = document.getElementById(
                        'div_dados_variaveis',
                      );
                      div_el.classList.toggle('divHide');
                    }}
                  />
                </div>
                <div
                  className='gocol wpMin125 w100 p-3 fs12 divHide'
                  id='div_dados_variaveis'
                >
                  {Object.keys(useVariacoes).map((key) => {
                    return (
                      <Form.Group
                        key={`div_${useVariacoes[key].CODIGO}`}
                        className='gocol align-items-center justify-content-start pb-2'
                      >
                        <Form.Label
                          className='fw500 ffSoft m-0 cBlue'
                          htmlFor={`input_${useVariacoes[key].NOME}`}
                        >
                          {useVariacoes[key].NOME}
                        </Form.Label>
                        <Form.Control
                          type='number'
                          step='0.01'
                          className='form-control '
                          size='sm'
                          name={`input_${useVariacoes[key].CODIGO}`}
                          id={`input_${useVariacoes[key].CODIGO}`}
                          value={useVariacoes[key].VALUE}
                          disabled
                          onChange={(e) => {
                            setUseVariacoes({
                              ...useVariacoes,
                              [key]: {
                                ...useVariacoes[key],
                                VALUE: parseFloat(e.target.value),
                              },
                            });
                          }}
                        />
                      </Form.Group>
                    );
                  })}
                </div>
              </div>
              <div className='gocol border rounded border-success p-3 fs12 m-2  '>
                <div className='gorow align-items-center justify-content-start'>
                  <h6 className='m-0 p-2'>Taxas Anuais de Juros %</h6>
                  <PKG.IconsUI
                    info={PKG.ui.expand.more}
                    clsnm='svg20 cLink  hovGreen mousePointerHover ms-2'
                    click={() => {
                      const div_el = document.getElementById('div_dados_taxas');
                      div_el.classList.toggle('divHide');
                    }}
                  />
                </div>
                <div
                   className='gocol wpMin125 w100 p-3 fs12 divHide'
                  id='div_dados_taxas'
                >
                  {Object.keys(linhas).map((key) => {
                    return (
                      <Form.Group
                        className='gocol align-items-center justify-content-start pb-2'
                        key={key}
                      >
                        <Form.Label
                          className='fw500 ffSoft m-0 cBlue'
                          htmlFor={key}
                        >
                          {linhas[key].NOME}
                        </Form.Label>
                        <Form.Control
                          type='number'
                          step={0.1}
                          className='form-control '
                          size='sm'
                          id={key}
                          disabled
                          name='taxa'
                          value={linhas[key].VALUE}
                          onChange={(e) => {
                            setLinhas({
                              ...linhas,
                              [key]: {
                                ...linhas[key],
                                VALUE: parseFloat(e.target.value),
                              },
                            });
                          }}
                        />
                      </Form.Group>
                    );
                  })}
                </div>
              </div>
            </div>
            {/* <div className='w100 gorow justify-content-between gocol600 wpMax800 mAuto'></div> */}
          </div>
        </form>
      </div>
      <div className={full ? 'gorow gocol' : 'gocol'}>
        <div className={full ? 'mx-2' : ''}>
          <div className='mt-3 gorow align-items-center justify-content-start'>
            <span className='fs11 fw400'>Análise de Possibilidades</span>
            <PKG.IconsUI
              info={PKG.ui.expand.more}
              clsnm='svg20 cLink hovGreen mousePointerHover'
              click={() => {
                const div_el = document.getElementById(
                  'div_financiamentos_result',
                );
                div_el.classList.toggle('divHide');
              }}
            />
          </div>
          <div
            id='div_financiamentos_result'
            className='divHide' // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
          >
            <table
              id='resultado'
              data-bs-theme={theme}
              className={`table table-striped table-hover table-sm `}
            >
              <thead>
                <tr>
                  <td className=''>Tabela</td>
                  <td className=''></td>
                  <td className={`divHide600 ${ismodal ? 'divHide' : ''}`}>
                    Taxa
                  </td>
                  <td className={`divHide600 ${ismodal ? 'divHide' : ''}`}>
                    Variação
                  </td>
                  <td className=''>Taxa Calc</td>
                  <td className={`divHide600 ${ismodal ? 'divHide' : ''}`}>
                    Encargos
                  </td>
                  <td className=''>Parcela SAC</td>
                  <td className=''>Parcela Price</td>

                  <td className=''>Total Sac</td>

                  <td className=''>Total Price</td>
                </tr>
              </thead>
              <tbody className='fs10'>{handleClick()}</tbody>
            </table>
          </div>
        </div>

        <div className={`my-2 ${full ? 'mx-2' : ''}`}>
          <div className='gorow align-items-center '>
            <span className='fs11 fw400'>Gráfico Dinâmico</span>
            <PKG.IconsUI
              info={PKG.ui.expand.more}
              clsnm='svg20 cLink hovGreen mousePointerHover'
              click={() => {
                setShowGraph(!showGraph);
              }}
            />
          </div>
          {showGraph && (
            <>
              <div className='fs10 gocol  justify-content-between align-items-center'>
                <div className='gocol gorowMin990 justify-content-start my-1 align-items-center'>
                  <span
                    className='fw600 wp150 fs08'
                    htmlFor='maxValorImovel'
                  >
                    Valor Máximo do Imóvel
                  </span>
                  <Form.Control
                    type='number'
                    size='sm'
                    className='form-control wp150'
                    name='maxValorImovel'
                    id='maxValorImovel'
                    value={graphMaxImovel}
                    onChange={(e) => setGraphMaxImovel(e.target.value)}
                  />
                </div>
                <div className='gocol gorowMin990  justify-content-start my-1  align-items-center'>
                  <span
                    className='fw600 wp150 fs08'
                    htmlFor='maxValorRenda'
                  >
                    Valor Máximo da Renda
                  </span>
                  <Form.Control
                    type='number'
                    size='sm'
                    className='form-control wp150'
                    name='maxValorRenda'
                    id='maxValorRenda'
                    value={graphMaxRenda}
                    onChange={(e) => setGraphMaxRenda(e.target.value)}
                  />
                </div>
              </div>

              <RendaGraph
                defineRenda={(v) => handleRenda(v)}
                defineImovel={(v) => handleImovel(v)}
                maxImovel={graphMaxImovel}
                maxRenda={graphMaxRenda}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
