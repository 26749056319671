import Form from 'react-bootstrap/Form';
import { useContext } from 'react';
import PackageContext from '../../packages/wookie/context/PackageContext';

export default function FormGroup({
  id,
  label,
  children = null,
  addFormControl = false,
  formControlProps = {},
  labelRequired = true,
  includeDenyField = false,
  denyFieldText = 'Não utilizarei',
  updateSimulacaoState = () => {},
  groupClass = 'bgOverlayDarkSoft p-3 mt-4',
  labelClass = 'input-label cWhite fw500 fs10 w100 mb-0',
}) {
  const { theme } = useContext(PackageContext);

  function FormInvalidFeedback({targetId}) {
    return (
      <Form.Control.Feedback
        type='invalid'
        className='customFormFeedback'
        id={`fdbk_${targetId}`}
      ></Form.Control.Feedback>
    );
  }
  const reqField = () => {
    const color = theme === 'light' ? 'cRed' : 'cDarkRed';
    return <span className={`ms-1 fs12 ${color}`}>*</span>;
  };

  const formControl = () => {
    if (addFormControl) {
      return (
        <Form.Control
          id={id}
          name={id}
          aria-describedby={id}
          {...formControlProps}
        />
      );
    }
    return null;
  };

  const fullLabel = () => {
    return (
      <>
        {label}
        {labelRequired && reqField()}
      </>
    );
  };

  return (
    <Form.Group
      className={groupClass}
      id={`box_${id}`}
    >
      <Form.Label
        htmlFor={id}
        className={labelClass}
      >
        {fullLabel()}
        {includeDenyField ? (
          <div className='m-0 p-1 fs11'>
            <Form.Check
              type='checkbox'
              id={`sem_${id}`}
              className={`stretchLabel border rounded border-secondary `}
              label={denyFieldText}
              onChange={(e) => {
                if (e.target.checked) {
                  updateSimulacaoState(id, 0);
                  document.getElementById(id).value = '0';
                  document.getElementById(id).required = false;
                  document.getElementById(id).classList.add('divHide');
                } else {
                  document.getElementById(id).classList.remove('divHide');
                  document.getElementById(id).required = true;
                  document.getElementById(id).value = '';
                  updateSimulacaoState(id, '');
                }
              }}
            />
          </div>
        ) : null}
      </Form.Label>
      {children}
      {formControl()}
      <FormInvalidFeedback targetId={id} />
    </Form.Group>
  );
}
