import '../assets/css/components/containerbox.css';
import { NavReturn } from '..';

export default function ContainerBox({
  main,
  navbar = null,
  navreturn = false,
  navreturnInfo = '',
  navreturnClass = '',
  sidebar = null,
  footer = null,
  overlayMain = false,
  classMain = '',
}) {
  return (
    <div className='containerBox'>
      {navbar}
      {navreturn ? <NavReturn info={navreturnInfo} className={navreturnClass} /> : null}
      <div className={`mainParent ${classMain}`}>
        <main className={overlayMain ? `bgOoverlayDarker` : ''}>
          {sidebar}
          <section
            className={sidebar ? 'containerBoxMainRight' : 'containerBoxMain'}
          >
            {main}
          </section>
        </main>
      </div>
      {footer}
    </div>
  );
}
