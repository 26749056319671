import axios from 'axios';
import CONF from '../config';

const { brand } = CONF;

export const defaults = {
  headers: {
    Authorization: '',
  },
};

export default async function API(endpoint, body) {
  if (body === undefined || body === null) {
    body = {};
  }

  const CONTENT = () => {
    switch (endpoint.content) {
      case 'files':
        return 'multipart/form-data';
      default:
        return 'application/json';
    }
  };

  const content = CONTENT();

  const api = axios.create({
    baseURL: endpoint.base,
    headers: {
      'Content-Type': content,
    },
  });
  let response;

  const token = sessionStorage.getItem('@App:token');
  api.defaults.headers.Authorization = token;
  body.token = token;
  body.brand = brand;
  if (endpoint.method === 'post') {
    response = await api.post(endpoint.endpoint, body);
  } else {
    response = await api.get(endpoint.endpoint);
  }
  if (response.data.success === false && response.data.message === 'Token inválido') {
    window.location.href = '/logout';

  }
  return response;
}

export async function FETCH(endpoint, form) {
  const token = sessionStorage.getItem('@App:token');
  form.append('token', token);
  const url = `${endpoint.base}${endpoint.endpoint}`;
  const response = await fetch(url, {
    method: 'POST',
    body: form,
  });
  return response;
}

export async function post(endpoint, body) {
  const API = axios.create({
    baseURL: CONF.ORA,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  let response;

  const token = sessionStorage.getItem('@App:token');
  API.defaults.headers.Authorization = token;
  body.token = token;
  body.brand = brand;
  response = await API(`${endpoint}/`, body);
  return response;
}

export async function postAPI(endpoint, body) {
  const API = axios.create({
    baseURL: CONF.BWAPI,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  let response;

  const token = sessionStorage.getItem('@App:token');
  API.defaults.headers.Authorization = token;
  body.token = token;
  body.brand = brand;
  response = await API(`${endpoint}/`, body);
  return response;
}

export async function get(endpoint) {
  const API = axios.create({
    baseURL: CONF.ORA,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  let response;

  const token = sessionStorage.getItem('@App:token');
  if (token) {
    API.defaults.headers.Authorization = token;
  }
  response = await API.get(`${endpoint}/`);
  return response;
}

export const postFiles = async (endpoint, body) => {
  const token = sessionStorage.getItem('@App:token');
  const API = axios.create({
    baseURL: CONF.ORA,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  body.token = token;
  API.defaults.headers.Authorization = token;
  const response = await API.post(`${endpoint}/`, body);
  return response;
};

export const postFilesAPI = async (endpoint, body) => {
  const token = sessionStorage.getItem('@App:token');
  const API = axios.create({
    baseURL: CONF.BWAPI,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  body.token = token;
  API.defaults.headers.Authorization = token;
  const response = await API.post(`${endpoint}/`, body);
  return response;
};

export function createURL(object = [{ key: '', value: '' }]) {
  let url = '';
  object.forEach((element) => {
    url += `${element.key}=${element.value}&`;
  });
  return url;
}
