import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Table, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  API,
  END,
  Footer,
  GlobalContext,
  PKG,
  CONF,
  NavBar,
} from '../config/imports';

export default function User() {
  const { checkLogin, navigate, store } = useContext(GlobalContext);
  const rolesArray = store().app_roles;	
  const { theme } = useContext(PKG.PackageContext);
  // const { post } = API;
  // const { user, users_roles } = end;
  const { codigo } = useParams();

  const [data, setData] = useState({});
  const [dataRoles, setDataRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [logged, setLogged] = useState(true);
  const [role, setRole] = useState('');

  useEffect(() => {
    const status = checkLogin();
    setLogged(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_user = useCallback(async () => {
    setLoading(true);
    try {
      const [userData, userRoles] = await Promise.all([
        API(END.user.read.user, {
          codigo,
        }),
        API(END.user.read.roles, {
          codigo,
        }),
      ]);
      setData(userData.data[0]);
      setDataRoles(userRoles.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [codigo]);

  const delete_role = useCallback(
    async (COD) => {
      setLoading(true);
      try {
        await API(END.users_roles.delete, {
          user_codigo: data.CODIGO,
          codigo: COD,
        });
        await get_user();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [data, get_user],
  );

  useEffect(() => {
    get_user();
  }, [codigo, get_user]);

  if (!logged) {
    navigate('/logout');
  } else {
    if (loading) {
      return (
        <>
          <PKG.LoadingDots conf={CONF} />
        </>
      );
    }

    return (
      <PKG.ContainerBox
        main={
          <div className='d-flex flex-column justify-content-start'>
            <h4 className='my-3 textContrastColor'>{data.NOME}</h4>
            <Table
              className='mt-2 mb-1 fs10 w100'
              variant={theme}
            >
              <thead>
                <tr>Dados do Usuário</tr>
              </thead>
              <tbody>
                <tr>
                  <td className='py-1 px-3'>Nome</td>
                  <td className='py-1 px-3'>{data.NOME}</td>
                </tr>
                <tr>
                  <td className='py-1 px-3'>CPF</td>
                  <td className='py-1 px-3'>{data.CPF}</td>
                </tr>
                <tr>
                  <td className='py-1 px-3'>Email</td>
                  <td className='py-1 px-3'>{data.EMAIL}</td>
                </tr>
                <tr>
                  <td className='py-1 px-3'>Telefone</td>
                  <td className='py-1 px-3'>{data.TELEFONE}</td>
                </tr>
                <tr>
                  <td className='py-1 px-3'>Perfil</td>
                  <td className='py-1 px-3'>{data.ROLE_DESCRIPTION}</td>
                </tr>
              </tbody>
            </Table>
            <div className='w100'>
              <h6 className='mt-4 mb-1 textContrastColor faLeft'>
                {dataRoles && dataRoles.length > 0
                  ? 'Perfis adicionais'
                  : 'Não há outros perfis cadastrados para o usuário'}
              </h6>
              <Table
                className='table table-striped table-hover fs10 table-vertical-middle'
                variant={theme}
              >

                <tbody>
                  {dataRoles &&
                    dataRoles.length > 0 &&
                    dataRoles.map((role) => (
                      <tr
                        key={`${role.id}_${role.ROLE_DESCRIPTION}`}
                        
                      >
                        <td className='wpMin300'>{role.ROLE_DESCRIPTION}</td>

                        <td>
                          <PKG.IconsUI
                            info={PKG.ui.action.delete.trash}
                            clsnm='cRed hovGreen mousePointerHover svg12'
                            tooltip='Remover perfil'
                            click={async () => {
                              Swal.fire({
                                title: 'Tem certeza?',
                                text: 'Você deseja deletar esse perfil do usuário?',
                                icon: 'warning',
                                showDenyButton: true,
                                showCancelButton: false,
                                confirmButtonColor: '#c41000',
                                confirmButtonText: 'Sim, deletar',
                                iconColor: '#c41000',
                                denyButtonColor: '#6cbe00',
                                denyButtonText: 'Não',
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  Swal.fire('Ok, deletado!', '', 'success');
                                  delete_role(role.CODIGO);
                                }
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
            <div>
              <h6 className='mt-5 mb-3'>Adicionar perfil ao usuário</h6>
              <div className='d-flex flex-row align-items-center'>
                <Form.Select
                  aria-label='perfil'
                  data-bs-theme={theme}
                  size='sm'
                  className='wp250'
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value=''>Selecione um perfil</option>
                  {rolesArray.map((role) => (
                    <option
                      key={`${role.ID}_${data.CODIGO}_${role.DESCRIPTION}`}
                      value={role.ID}
                    >
                      {role.DESCRIPTION}
                    </option>
                  ))}
                </Form.Select>
                <Button
                  variant='primary'
                  size='sm'
                  className='m-2 px-2'
                  onClick={async () => {
                    if (!role) {
                      return;
                    }
                    setLoading(true);

                    try {
                      await API(END.users_roles.create, {
                        user_codigo: data.CODIGO,
                        role_id: role,
                      });
                      await get_user();
                      setLoading(false);
                    } catch (error) {
                      console.error(error);
                      setLoading(false);
                    }
                  }}
                >
                  Adicionar perfil
                </Button>
              </div>
            </div>
          </div>
        }
        footer={<Footer />}
        navbar={<NavBar />}
        overlayMain={false}
        classMain='container align-items-start'
        navreturn={true}
        navreturnClass='container'
      />
    );
  }
}
