import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import '../../../assets/css/app/section.news.css';

import {
  GlobalContext,
  PKG,
  NewsCharts,
  NewsTimelineItem,
  API,
  CONF,
  END,
  TaxasCharts,
} from '../../../config/imports';

export default function News() {
  const { checkLogin, setShowSection } = useContext(GlobalContext);
  const [logged, setLogged] = useState(false);

  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const [months, setMonths] = useState([]);
  const [taxas, setTaxas] = useState([]);

  const [columns, setColumns] = useState(2);
  const sectionRef = useRef(null);

  const cards = useMemo(
    () => [
      {
        title: 'Simulações',
        icon: <PKG.IC.IoStatsChart className='svg22 mt-2' />,
        color: 'warning',
        value: `
Totais: ${numbers.SIMULACOES}
Em andamento: ${numbers.SIMULACOES_ACTIVE}
`,
        section: 'simulacoes',
      },
 
      {
        title: 'Processos',
        icon: <PKG.IC.MdAutoGraph className='svg22 mt-2' />,
        color: 'danger',
        value: `
Totais: ${numbers.PROCESSOS}
Em andamento: ${numbers.PROCESSOS_ACTIVE}
Finalizados: ${numbers.PROCESSOS_DONE}
Aguardando: ${numbers.PROCESSOS_WAITING}
`,
        section: 'processos',
      },
    
      {
        title: 'Crédito gerado',
        icon: <PKG.IC.BsCashCoin className='svg22 mt-2' />,
        color: 'success',
        value: `
Finalizado: ${parseFloat(numbers.TOTAL_IMOVEL_VALOR).toLocaleString('pt-BR', {
  style: 'currency',
  currency: 'BRL',
})}
Em simulação: ${parseFloat(numbers.TOTAL_IMOVEL_VALOR_SIM).toLocaleString('pt-BR', {
  style: 'currency',
  currency: 'BRL',
})}
Em andamento: ${parseFloat(numbers.TOTAL_IMOVEL_VALOR_PROC_ACTIVE).toLocaleString('pt-BR', {
  style: 'currency',
  currency: 'BRL',
})}
Pendente: ${parseFloat(numbers.TOTAL_IMOVEL_VALOR_PROC_WAITING).toLocaleString('pt-BR', {
  style: 'currency',
  currency: 'BRL',
})}
`,
      },
      {
        title: 'Cadastros',
        icon: <PKG.IC.FaBuildingFlag className='svg22 mt-2' />,
        color: 'primary',
        value: `
Clientes: ${numbers.CLIENTES}
Imobiliárias: ${numbers.IMOBILIARIAS}
Corretores: ${numbers.CORRETORES}
`,
        section: 'imobiliarias',
      }
    ],
    [numbers],
  );

  const updateColumns = useCallback(() => {
    const sectionWidth = sectionRef.current?.offcontainer || 360;
    const cols = Math.floor(sectionWidth / 350);
    if (cols > 2) {
      setColumns(4);
    } else {
      setColumns(cols);
    } 
  }, []);

  useEffect(() => {
    updateColumns();
    window.addEventListener('resize', updateColumns);
    return () => window.removeEventListener('resize', updateColumns);
  }, [updateColumns]);

  useEffect(() => {
    const logged = checkLogin();
    setLogged(logged);

    const fetchData = async () => {
      setLoading(true);
      try {
        const [latestResponse, numbsResponse, monthesResponse, taxasResponse] =
          await Promise.all([
            API(END.views.latest, { brand: CONF.brand }),
            API(END.views.numbs, { brand: CONF.brand }),
            API(END.views.monthesnumbers, { brand: CONF.brand }),
            API(END.views.taxas, { brand: CONF.brand }),
          ]);

        setNews(latestResponse.data);
        setNumbers(numbsResponse.data[0]);

        const data = monthesResponse.data;
        const rendaTotal = data.find(
          (item) => item.LINHA === 'Renda total das Simulações',
        );
        const totalSimulacoes = data.find(
          (item) => item.LINHA === 'Total de Simulações',
        );
        const totalImoveis = data.find(
          (item) => item.LINHA === 'Total de Imóveis em Simulações',
        );

        data.push({
          LINHA: 'Renda Média das Simulações',
          TENANT_CODIGO: rendaTotal.TENANT_CODIGO,
          ...Object.fromEntries(
            Object.keys(rendaTotal)
              .filter((key) => key.startsWith('MONTH'))
              .map((month) => [
                month,
                totalSimulacoes[month] === 0
                  ? 0
                  : rendaTotal[month] / totalSimulacoes[month],
              ]),
          ),
        });

        data.push({
          LINHA: 'Imóvel Médio',
          TENANT_CODIGO: totalImoveis.TENANT_CODIGO,
          ...Object.fromEntries(
            Object.keys(totalImoveis)
              .filter((key) => key.startsWith('MONTH'))
              .map((month) => [
                month,
                totalSimulacoes[month] === 0
                  ? 0
                  : totalImoveis[month] / totalSimulacoes[month],
              ]),
          ),
        });

        setMonths(data);
        setTaxas(taxasResponse.data);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    if (logged) {
      fetchData();
      updateColumns();
    }
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <PKG.Loading />;
  }

  if (!logged) return null;

  return (
    <div className='container mt-5'>
      <div className='gorow align-items-center mb-2'>
        <span className='ffSoft textContrastColor'>Seus Números</span>
        <PKG.IconsUI
          info={PKG.ui.expand.more}
          clsnm='svg18 cLink hovGreen mousePointerHover ms-2'
          click={() => {
            updateColumns();
            document.getElementById('carsNumbs').classList.toggle('divHide');
          }}
        />
      </div>

      <section
        className='cardBoxLine '
        id='carsNumbs'
        ref={sectionRef}
        style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}
      >
        {cards.map((card, index) => (
          <div
            onClick={() => setShowSection(card.section)}
            key={`card_${index}`}
            className={`p-3 border rounded  bor${card.color} border-5 faCenter cardItemNews`}
          >
            <div className='faCenter'>
              <div className='fs09 small'>{card.title}</div>
              <div className='fs12 fw400 whiteSpacePre'>{card.value}</div>
            </div>
            {card.icon}
          </div>
        ))}
      </section>

      <p className='ffSoft textContrastColor'>Últimas atualizações</p>
      <ul className='ffReg liNews mb-5'>
        {news &&
          news.map((item, index) => (
            <NewsTimelineItem
              item={item}
              key={`news_${item.CODIGO}_${index}`}
            />
          ))}
      </ul>

      <div className='my-5 divHide divShowMin600 flex-column fs12'>
        <p className='ffSoft textContrastColor pt-5'>
          Crédito gerado nos últimos 12 meses
        </p>
        {months.length > 0 && <NewsCharts data={months} />}
        <p className='ffSoft textContrastColor pt-5'>
          Índices nos últimos 12 meses
        </p>
        {taxas.length > 0 && <TaxasCharts data={taxas} />}
      </div>
    </div>
  );
}
