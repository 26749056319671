import { useContext, useEffect, useState } from 'react';
import {
  GlobalContext,
  ProtocolosTable,
  CONF,
  PKG,
  API,
  END,
} from '../../config/imports';

export default function Protocolos() {
  const { checkLogin, logout } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);

  const [protocolos, setProtocolos] = useState([]);
  const [filtered, setFiltered] = useState([]);

  const filter_protocolos = () => {
    const terms = document.getElementById('filter-protocolos').value;

    if (terms.length > 2) {
      let filtered_array =
        protocolos &&
        protocolos.filter(
          ({ DESCRICAO, STATUS, EXIGENCIA, ORGAO, NUMERO, SITUACAO }) =>
            (DESCRICAO &&
              DESCRICAO.toLowerCase().includes(terms.toLowerCase())) ||
            (STATUS && STATUS.toLowerCase().includes(terms.toLowerCase())) ||
            (EXIGENCIA &&
              EXIGENCIA.toLowerCase().includes(terms.toLowerCase())) ||
            (ORGAO && ORGAO.toLowerCase().includes(terms.toLowerCase())) ||
            (NUMERO && NUMERO.toLowerCase().includes(terms.toLowerCase())) ||
            (SITUACAO && SITUACAO.toLowerCase().includes(terms.toLowerCase())),
        );
      setFiltered(filtered_array);
    }

    if (terms.length < 3) {
      setFiltered(protocolos);
    }
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const protocolosResponse = await API(END.views.protocolos, {});
        const protocolosData = protocolosResponse.data;
        if (protocolosData) {
          setProtocolos(protocolosData);
          setFiltered(protocolosData);
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };
    const status = checkLogin();
    if (status) {
      getData();
    } else {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <PKG.LoadingDots conf={CONF} />;
  }

  return (
    <div className='container mt-5'>
      <h4 className='hc-section-title'>Protocolos</h4>
      <p className='mt-2 mb-5'>
        <span className='me-2 ffSoft'>Filtrar por</span>
        <input
          type='text'
          id='filter-protocolos'
          className='filterTable'
          onChange={filter_protocolos}
          pattern='.{3,20}'
        />
      </p>
      {filtered && <ProtocolosTable view_protocolos={filtered} />}
    </div>
  );
}
