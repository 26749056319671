import React, { useContext, useState } from 'react';
import { GlobalContext, PKG } from '../../../config/imports';

export default function SimulacaoStatusDrop({
  simId,
  procCod,
  statusChange = null,
}) {
  const { a } = useContext(GlobalContext);
  const { status_simulacoes } = a;
  const [stats, setStatus] = useState(simId);

  const handleStatus = (id) => {
    setStatus(id);
    statusChange(id);
  };

  return (
    <PKG.CustomDropdown
      arrayObj={status_simulacoes}
      defaultValue={simId}
      value={stats}
      changeCallApi={handleStatus}
      identification={`proc_sim_status_${procCod}`}
    />
  );
}
