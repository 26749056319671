const ORDS =
  'https://g3c32193a94f6bf-bwhabita.adb.sa-saopaulo-1.oraclecloudapps.com/ords/habita';
const HAPI = 'https://api.bewook.com/habita';
const OAPI = 'https://core.bewook.com/habita';
const GAPI = 'https://core.bewook.com/global';

const END = {
  auth: {
    login: {
      base: ORDS,
      endpoint: '/auth/login/',
      method: 'post',
      content: 'json',
    },
    logout: {
      base: ORDS,
      endpoint: '/auth/logout/',
      method: 'post',
      content: 'json',
    },
    authorized: {
      base: ORDS,
      endpoint: '/auth/authorized/',
      method: 'post',
      content: 'json',
    },
    register: {
      base: ORDS,
      endpoint: '/auth/register/',
      method: 'post',
      content: 'json',
    },
    recover: {
      base: ORDS,
      endpoint: '/auth/recover/',
      method: 'post',
      content: 'json',
    },
    reset: {
      base: ORDS,
      endpoint: '/auth/reset/',
      method: 'post',
      content: 'json',
    },
    user: {
      base: ORDS,
      endpoint: '/auth/user/',
      method: 'post',
      content: 'json',
    },
    roles: {
      base: ORDS,
      endpoint: '/auth/roles/',
      method: 'post',
      content: 'json',
    },
  },
  andamentos: {
    create: {
      andamento: {
        base: ORDS,
        endpoint: '/processo_andamentos/c/andamento/',
        method: 'post',
        content: 'json',
      },
      doc: {
        base: ORDS,
        endpoint: '/processo_andamentos/c/docs/',
        method: 'post',
        content: 'files',
      },
    },
    delete: {
      base: ORDS,
      endpoint: '/processo_andamentos/d/',
      method: 'post',
      content: 'json',
    },
    read: {
      all: {
        base: ORDS,
        endpoint: '/processo_andamentos/r/',
        method: 'post',
        content: 'json',
      },
      andamento: {
        base: ORDS,
        endpoint: '/processo_andamentos/r/andamento/',
        method: 'post',
        content: 'json',
      },
      docs: {
        // <<<<<<<<<<<< analisar
        base: ORDS,
        endpoint: '/processo_andamentos/r/docs/',
        method: 'post',
        content: 'json',
      },
      tasks: {
        base: ORDS,
        endpoint: '/processo_andamentos/r/tasks/',
        method: 'post',
        content: 'json',
      },
      agenda: {
        base: ORDS,
        endpoint: '/processo_andamentos/r/agenda/',
        method: 'post',
        content: 'json',
      },
      mensagem: {
        base: ORDS,
        endpoint: '/processo_andamentos/r/mensagem/',
        method: 'post',
        content: 'json',
      },
    },
    update: {
      title: {
        base: ORDS,
        endpoint: '/processo_andamentos/u/title/',
        method: 'post',
        content: 'json',
      },
      andamento: {
        base: ORDS,
        endpoint: '/processo_andamentos/u/andamento/',
        method: 'post',
        content: 'json',
      },
      nota: {
        base: ORDS,
        endpoint: '/processo_andamentos/u/nota/',
        method: 'post',
        content: 'json',
      },
      prazo: {
        base: ORDS,
        endpoint: '/processo_andamentos/u/prazo/',
        method: 'post',
        content: 'json',
      },
      prioridade: {
        base: ORDS,
        endpoint: '/processo_andamentos/u/prioridade/',
        method: 'post',
        content: 'json',
      },
      status: {
        base: ORDS,
        endpoint: '/processo_andamentos/u/status/',
        method: 'post',
        content: 'json',
      },
      visivel: {
        base: ORDS,
        endpoint: '/processo_andamentos/u/visivel/',
        method: 'post',
        content: 'json',
      },
      notificar: {
        base: ORDS,
        endpoint: '/processo_andamentos/u/notificar/',
        method: 'post',
        content: 'json',
      },
    },
  },
  cidades: {
    create: {
      base: ORDS,
      endpoint: '/cidades/c/',
      method: 'post',
      content: 'json',
    },
    delete: {
      base: ORDS,
      endpoint: '/cidades/d/',
      method: 'post',
      content: 'json',
    },
    read: {
      base: ORDS,
      endpoint: '/cidades/r/',
      method: 'get',
      content: 'json',
    },
    update: {},
  },
  cri: {
    registro: {
      base: OAPI,
      endpoint: '/cri/registro/',
      method: 'post',
      content: 'json',
    },
    certidao: {
      base: OAPI,
      endpoint: '/cri/certidao/',
      method: 'post',
      content: 'json',
    },
    calculo: {
      base: OAPI,
      endpoint: '/cri/calculo/',
      method: 'post',
      content: 'json',
    },
    titulos: {
      base: OAPI,
      endpoint: '/cri/titulos/',
      method: 'post',
      content: 'json',
    },
    pessoa_juridica: {
      base: OAPI,
      endpoint: '/cri/pessoa_juridica/',
      method: 'post',
      content: 'json',
    },
  },
  filiais: {
    create: {
      base: ORDS,
      endpoint: '/filiais/c/',
      method: 'post',
      content: 'json',
    },
    delete: {
      base: ORDS,
      endpoint: '/filiais/d/',
      method: 'post',
      content: 'json',
    },
    read: {
      base: ORDS,
      endpoint: '/filiais/r/',
      method: 'post',
      content: 'json',
    },
    update: {
      active: {
        base: ORDS,
        endpoint: '/filiais/u/active/',
        method: 'post',
        content: 'json',
      },
    },
  },
  indices: {
    api: {
      igpm: {
        base: OAPI,
        endpoint: '/indices/igpm/',
        method: 'post',
        content: 'json',
      },
      ipca: {
        base: OAPI,
        endpoint: '/indices/ipca/',
        method: 'post',
        content: 'json',
      },
      selic: {
        base: OAPI,
        endpoint: '/indices/selic/',
        method: 'post',
        content: 'json',
      },
      tr: {
        base: OAPI,
        endpoint: '/indices/tr/',
        method: 'post',
        content: 'json',
      },
    },
    create: {
      base: ORDS,
      endpoint: '/indices/c/',
      method: 'post',
      content: 'json',
    },
    delete: {
      base: ORDS,
      endpoint: '/indices/d/',
      method: 'post',
      content: 'json',
    },
    read: {
      base: ORDS,
      endpoint: '/indices/r/',
      method: 'post',
      content: 'json',
    },
  },
  lead: {
    create: {
      base: ORDS,
      endpoint: '/lead/c/',
      method: 'post',
      content: 'json',
    },
    read: {
      all: {
        base: ORDS,
        endpoint: '/lead/r/',
        method: 'post',
        content: 'json',
      },
      codigo: {
        base: ORDS,
        endpoint: '/lead/r/codigo/',
        method: 'post',
        content: 'json',
      },
    },
  },
  mail: {
    docs: {
      base: HAPI,
      endpoint: '/mail/docs/',
      method: 'post',
      content: 'files',
    },
    text: {
      base: HAPI,
      endpoint: '/mail/text/',
      method: 'post',
      content: 'json',
    },
  },
  ocr: {
    rg: {
      base: GAPI,
      endpoint: '/ocr/rg/',
      method: 'post',
      content: 'files',
    },
  },
  operacoes: {
    create: {
      base: ORDS,
      endpoint: '/operacoes/c/',
      method: 'post',
      content: 'json',
    },
    delete: {
      base: ORDS,
      endpoint: '/operacoes/d/',
      method: 'post',
      content: 'json',
    },
    read: {
      base: ORDS,
      endpoint: '/operacoes/r/',
      method: 'get',
      content: 'json',
    },
    update: {},
  },
  processo_etapa: {
    read: {
      all: {
        base: ORDS,
        endpoint: '/processo_etapa/r/',
        method: 'post',
        content: 'json',
      },
    },
    update: {
      done: {
        base: ORDS,
        endpoint: '/processo_etapa/u/done/',
        method: 'post',
        content: 'json',
      },
    },
  },
  processos: {
    create: {
      base: ORDS,
      endpoint: '/processos/c/',
      method: 'post',
      content: 'json',
    },
    delete: {
      base: ORDS,
      endpoint: '/api/processos/d/',
      method: 'post',
      content: 'json',
    },
    read: {
      codigo: {
        base: ORDS,
        endpoint: '/api/processos/r/codigo/',
        method: 'post',
        content: 'json',
      },
      user: {
        base: ORDS,
        endpoint: '/processos/r/user/',
        method: 'post',
        content: 'json',
      },
      id: {
        base: ORDS,
        endpoint: '/processos/r/id/',
        method: 'post',
        content: 'json',
      },
      full: {
        base: ORDS,
        endpoint: '/api/processos/r/full/',
        method: 'post',
        content: 'json',
      },
      corretor: {
        base: ORDS,
        endpoint: '/processos/r/corretor/',
        method: 'post',
        content: 'json',
      },
      imobiliaria: {
        base: ORDS,
        endpoint: '/processos/r/imobiliaria/',
        method: 'post',
        content: 'json',
      },
      correspondente: {
        base: ORDS,
        endpoint: '/processos/r/correspondente/',
        method: 'post',
        content: 'json',
      },
      validar: {
        base: ORDS,
        endpoint: '/processos/r/validar/',
        method: 'post',
        content: 'json',
      },
    },
    update: {
      status_simulacao: {
        base: ORDS,
        endpoint: '/api/processos/u/status_simulacao/',
        method: 'post',
        content: 'json',
      },
      full: {
        base: ORDS,
        endpoint: '/api/processos/u/full/',
        method: 'post',
        content: 'json',
      },
    },
    find: {
      base: ORDS,
      endpoint: '/processos/find/',
      method: 'post',
      content: 'json',
    },
  },
  processo_andamentos: {
    create: {
      andamento: {
        base: ORDS,
        endpoint: '/api/processo_andamentos/c/andamento/',
        method: 'post',
        content: 'json',
      },
      doc: {
        base: ORDS,
        endpoint: '/api/processo_andamentos/c/docs/',
        method: 'post',
        content: 'files',
      },
      task: {
        base: ORDS,
        endpoint: '/api/processo_andamentos/c/tasks/',
        method: 'post',
        content: 'json',
      },
    },
    delete: {
      base: ORDS,
      endpoint: '/api/processo_andamentos/d/',
      method: 'post',
      content: 'json',
    },
    read: {
      all: {
        base: ORDS,
        endpoint: '/api/processo_andamentos/r/',
        method: 'post',
        content: 'json',
      },
      docs: {
        base: ORDS,
        endpoint: '/api/processo_andamentos/r/docs/',
        method: 'post',
        content: 'json',
      },
      tasks: {
        base: ORDS,
        endpoint: '/api/processo_andamentos/r/tasks/',
        method: 'post',
        content: 'json',
      },
    },
    update: {
      andamento: {
        base: ORDS,
        endpoint: '/api/processo_andamentos/u/andamento/',
        method: 'post',
        content: 'json',
      },
      nota: {
        base: ORDS,
        endpoint: '/api/processo_andamentos/u/nota/',
        method: 'post',
        content: 'json',
      },
      notificar: {
        base: ORDS,
        endpoint: '/api/processo_andamentos/u/notificar/',
        method: 'post',
        content: 'json',
      },
      prazo: {
        base: ORDS,
        endpoint: '/api/processo_andamentos/u/prazo/',
        method: 'post',
        content: 'json',
      },
      prioridade: {
        base: ORDS,
        endpoint: '/api/processo_andamentos/u/prioridade/',
        method: 'post',
        content: 'json',
      },
      status: {
        base: ORDS,
        endpoint: '/api/processo_andamentos/u/status/',
        method: 'post',
        content: 'json',
      },
      title: {
        base: ORDS,
        endpoint: '/api/processo_andamentos/u/title/',
        method: 'post',
        content: 'json',
      },
      visivel: {
        base: ORDS,
        endpoint: '/api/processo_andamentos/u/visivel/',
        method: 'post',
        content: 'json',
      },
    },
  },

  protocolos: {
    create: {
      base: ORDS,
      endpoint: '/protocolos/c/',
      method: 'post',
      content: 'json',
    },
    delete: {
      base: ORDS,
      endpoint: '/protocolos/d/',
      method: 'post',
      content: 'json',
    },
    read: {
      base: ORDS,
      endpoint: '/protocolos/r/',
      method: 'post',
      content: 'json',
    },
    update: {
      status: {
        base: ORDS,
        endpoint: '/protocolos/u/status/',
        method: 'post',
        content: 'json',
      },
    },
  },
  realstate: {
    read: {
      base: ORDS,
      endpoint: '/realstate/r/',
      method: 'post',
      content: 'json',
    },
  },
  roles: {
    read: {
      base: ORDS,
      endpoint: '/roles/r/',
      method: 'post',
      content: 'json',
    },
  },
  sim: {
    create: {
      base: ORDS,
      endpoint: '/simulacoes/c/',
      method: 'post',
      content: 'json',
    },
    delete: {
      base: ORDS,
      endpoint: '/simulacoes/d/',
      method: 'post',
      content: 'json',
    },
    read: {
      all: {
        base: ORDS,
        endpoint: '/simulacoes/r/',
        method: 'post',
        content: 'json',
      },
      codigo: {
        base: ORDS,
        endpoint: '/simulacoes/r/codigo/',
        method: 'post',
        content: 'json',
      },
    },

    update: {
      anotacoes: {
        base: ORDS,
        endpoint: '/simulacoes/u/anotacoes/',
        method: 'post',
        content: 'json',
      },
      active: {
        base: ORDS,
        endpoint: '/simulacoes/u/active/',
        method: 'post',
        content: 'json',
      },
    },
    mail: {
      base: HAPI,
      endpoint: '/simulacoes/mail/',
      method: 'post',
      content: 'json',
    },
    find: {
      base: ORDS,
      endpoint: '/simulacoes/find/',
      method: 'post',
      content: 'json',
    },
    all: {
      base: ORDS,
      endpoint: '/simulacoes/all/',
      method: 'post',
      content: 'json',
    },
    telegram: {
      notify_admin: {
        base: OAPI,
        endpoint: '/telegram/API/notify_admin/',
        method: 'post',
        content: 'json',
      },
    },
  },
  sim_docs: {
    create: {
      base: ORDS,
      endpoint: '/simdocs/c/',
      method: 'post',
      content: 'files',
    },
    delete: {
      base: ORDS,
      endpoint: '/simdocs/d/',
      method: 'post',
      content: 'json',
    },
    read: {
      doc: {
        base: ORDS,
        endpoint: '/simdocs/r/doc/',
        method: 'post',
        content: 'json',
      },
      all: {
        base: ORDS,
        endpoint: '/simdocs/r/all/',
        method: 'post',
        content: 'json',
      },
    },
  },
  support: {
    create: {
      base: ORDS,
      endpoint: '/support/c/',
      method: 'post',
      content: 'json',
    },
    email: {
      base: HAPI,
      endpoint: '/support/ticket/',
      method: 'post',
      content: 'json',
    },
    read: {
      base: ORDS,
      endpoint: '/support/r/',
      method: 'post',
      content: 'json',
    },
    delete: {
      base: ORDS,
      endpoint: '/support/d/',
      method: 'post',
      content: 'json',
    },
    update: {
      title: {
        base: ORDS,
        endpoint: '/support/u/title/',
        method: 'post',
        content: 'json',
      },
      andamento: {
        base: ORDS,
        endpoint: '/support/u/andamento/',
        method: 'post',
        content: 'json',
      },
      prazo: {
        base: ORDS,
        endpoint: '/support/u/prazo/',
        method: 'post',
        content: 'json',
      },
      prioridade: {
        base: ORDS,
        endpoint: '/support/u/prioridade/',
        method: 'post',
        content: 'json',
      },
      status: {
        base: ORDS,
        endpoint: '/support/u/status/',
        method: 'post',
        content: 'json',
      },
    },
  },
  tasks: {
    create: {
      base: ORDS,
      endpoint: '/tasks/c/',
      method: 'post',
      content: 'json',
    },
    read: {
      tenant: {
        base: ORDS,
        endpoint: '/tasks/r/tenant/',
        method: 'post',
        content: 'json',
      },
      user: {
        base: ORDS,
        endpoint: '/tasks/r/user/',
        method: 'post',
        content: 'json',
      },
    },
    delete: {
      base: ORDS,
      endpoint: '/tasks/d/',
      method: 'post',
      content: 'json',
    },
    update: {
      title: {
        base: ORDS,
        endpoint: '/tasks/u/title/',
        method: 'post',
        content: 'json',
      },
      andamento: {
        base: ORDS,
        endpoint: '/tasks/u/andamento/',
        method: 'post',
        content: 'json',
      },
      prazo: {
        base: ORDS,
        endpoint: '/tasks/u/prazo/',
        method: 'post',
        content: 'json',
      },
      prioridade: {
        base: ORDS,
        endpoint: '/tasks/u/prioridade/',
        method: 'post',
        content: 'json',
      },
      status: {
        base: ORDS,
        endpoint: '/tasks/u/status/',
        method: 'post',
        content: 'json',
      },
    },
  },
  tenants: {
    logo_link: {
      base: ORDS,
      endpoint: '/tenants/logo_link/',
      method: 'post',
      content: 'json',
    },
  },
  user: {
    create: {
      base: ORDS,
      endpoint: '/users/c/',
      method: 'post',
      content: 'json',
    },
    delete: {
      base: ORDS,
      endpoint: '/users/d/',
      method: 'post',
      content: 'json',
    },
    read: {
      user: {
        base: ORDS,
        endpoint: '/users/r/codigo/',
        method: 'post',
        content: 'json',
      },
      gestao: {
        base: ORDS,
        endpoint: '/users/r/gestao/',
        method: 'post',
        content: 'json',
      },
      roles: {
        base: ORDS,
        endpoint: '/users/r/roles/codigo/',
        method: 'post',
        content: 'json',
      },
    },
    update: {
      role: {
        base: ORDS,
        endpoint: '/users/u/role/',
        method: 'post',
        content: 'json',
      },
      status: {
        base: ORDS,
        endpoint: '/users/u/status/',
        method: 'post',
        content: 'json',
      },
      password: {
        base: ORDS,
        endpoint: '/users/u/password/',
        method: 'post',
        content: 'json',
      },
      active: {
        base: ORDS,
        endpoint: '/users/u/active/',
        method: 'post',
        content: 'json',
      },
      telefone: {
        base: ORDS,
        endpoint: '/users/u/telefone/',
        method: 'post',
        content: 'json',
      },
      email: {
        base: ORDS,
        endpoint: '/users/u/email/',
        method: 'post',
        content: 'json',
      },
      nome: {
        base: ORDS,
        endpoint: '/users/u/nome/',
        method: 'post',
        content: 'json',
      },
      cpf: {
        base: ORDS,
        endpoint: '/users/u/cpf/',
        method: 'post',
        content: 'json',
      },
    },
  },
  users_roles: {
    create: {
      base: ORDS,
      endpoint: '/users_roles/c/',
      method: 'post',
      content: 'json',
    },
    delete: {
      base: ORDS,
      endpoint: '/users_roles/d/',
      method: 'post',
      content: 'json',
    },
    update: {
      active: {
        base: ORDS,
        endpoint: '/users_roles/u/active/',
        method: 'post',
        content: 'json',
      },
    },
    read: {
      base: ORDS,
      endpoint: '/users_roles/r/user_codigo/',
      method: 'post',
      content: 'json',
    },
  },
  views: {
    apoio: {
      base: ORDS,
      endpoint: '/v/apoio/',
      method: 'post',
      content: 'json',
    },
    latest: {
      base: ORDS,
      endpoint: '/v/latest/',
      method: 'post',
      content: 'json',
    },
    monthes: {
      base: ORDS,
      endpoint: '/v/monthes/',
      method: 'post',
      content: 'json',
    },
    monthesnumbers: {
      base: ORDS,
      endpoint: '/v/monthesnumbers/',
      method: 'post',
      content: 'json',
    },
    numbs: {
      base: ORDS,
      endpoint: '/v/numbers/',
      method: 'post',
      content: 'json',
    },
    procdocs: {
      base: ORDS,
      endpoint: '/api/views/procdocs/',
      method: 'post',
      content: 'json',
    },

    protocolos_processo: {
      base: ORDS,
      endpoint: '/api/views/protocolos_processo/',
      method: 'post',
      content: 'json',
    },
    protocolos: {
      base: ORDS,
      endpoint: '/api/views/protocolos/',
      method: 'post',
      content: 'json',
    },
    taxas: {
      base: ORDS,
      endpoint: '/v/taxas/',
      method: 'post',
      content: 'json',
    },
  },
};

export default END;
