import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext, CONF, Dashboard, PKG, IMG } from '../config/imports';
import Button from 'react-bootstrap/Button';

export default function Public() {
  const { checkLogin, loading, navigate } = useContext(GlobalContext);
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    const status = checkLogin();
    setLogged(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <PKG.Loading />;
  if (logged) return <Dashboard />;

  return (
    <PKG.ContainerBox
      overlayMain
      classMain='bgHandshake appBg vh100'
      clsnmTextHeader='fs18 textContrastLow'
      main={
        <div className='w80 h40 hpMin500 gocol align-items-center justify-content-center rounded  wpMax700 pt-5 bgOverlayDarker faCenter'>
          <img
            src={CONF.images.dark.logotext}
            className={`w50 wpMax250 mb-1`}
            alt={CONF.tenant}
          />

          <p
            className='fs18 mousePointerHover cYellow fw600 ffSoft'
            onClick={() => {
              navigate('/login');
            }}
          >
            Faça login
          </p>

          <PKG.BoxTextImageInside
            image={IMG.img_news}
            text={
              <>
                <h1 className='fs12 fw700 ffSoft cYellow'>
                  Temos um app exclusivo
                </h1>
                <h2 className='fs12 fw600 cWhite'>
                  Acompanhe em tempo real o seu processo de financiamento
                </h2>
                <Button
                  size='sm'
                  className='btn btn-primary mt-3'
                  id='btnSaibaMais'
                  onClick={() => {
                    document.getElementById('saibamais').classList.toggle('divHide');
                    document.getElementById('btnSaibaMais').classList.toggle('divHide');
                  }}
                >
                  saiba mais
                </Button>
                <div
                  id='saibamais'
                  className='pt-1 divHide cWhite'
                >
                  <p className='fs10'>
                    Aqui você é atendido por um especialista em financiamento
                    imobiliário, que vai te ajudar a encontrar a melhor solução
                    para o seu caso.
                  </p>
                  <h2 className='fs10 fw600 mt-3'>
                    Se você é corretor ou imobiliária, temos um acesso especial
                    para você:{' '}
                    <span className='fw400'>
                      com o nosso dashboard, você pode acompanhar o processo de
                      financiamento dos seus clientes.
                    </span>
                  </h2>
                </div>
              </>
            }
            clsnm='p0 m0'
          />
        </div>
      }
    />
  );
}
