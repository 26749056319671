import { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';

import { BadgeCompor, BadgeFull, BadgeShare } from '../../common/Badges';
import API from '../../../services/requests';
import END from '../../../services/endpoints';
import ProcessoModal from './ProcessoModal';
import SimulacaoModal from './SimulacaoModal';
import SimulacaoStatusDrop from './SimulacaoStatusDrop';
import GlobalContext from '../../../context/GlobalContext';
import { IconsUI, ui } from '../../../packages/wookie/icons';
import * as SCR from '../../../packages/wookie/package.script';
import PackageContext from '../../../packages/wookie/context/PackageContext';

export default function SimulacaoLine({ obj, upd }) {
  const { theme } = useContext(PackageContext);
  const { navigate } = useContext(GlobalContext);

  const { createLinkWhatsapp, curr } = SCR.Utils;
  const [showSimulacao, setShowSimulacao] = useState(false);
  const [showProcessoCreate, setShowProcessoCreate] = useState(false);
  const [loading, setLoading] = useState();

  const [modalSimulacaoFull, setModalSimulacaoFull] = useState(true);

  const handleCloseSimulacao = () => {
    setShowSimulacao(false);
    upd();
  };
  const handleShowSimulacao = () => setShowSimulacao(true);

  const handleSetSimulacaoModalFull = () => {
    const newstate = !modalSimulacaoFull;
    setModalSimulacaoFull(newstate);
  };

  const handleCloseProcessoCreate = () => setShowProcessoCreate(false);
  // const handleShowProcessoCreate = () => {
  //   setShowProcessoCreate(true);
  // };

  const handleObj = (newObj) => {
    obj = newObj;
  };

  function simpleDescription(description) {
    const simplifiedDescriptions = {
      'Aquisição de Imóvel Novo': 'Imóvel novo',
      Construção: 'Construção',
      'Reforma ou Ampliação': 'Reforma',
      'Aquisição de Imóvel Usado': 'Imóvel usado',
      'Aquisição de Terreno': 'Terreno',
      'Empréstimo Garantido por Imóvel': 'Empréstimo',
      'Imóveis CAIXA': 'Imóveis CAIXA',
    };

    return simplifiedDescriptions[description] || description; // Returns simplified description or the original if not found
  }

  obj.tipo = obj.SIMULACAO_COMPLETA ? 'Completa' : 'Simplificada';

  obj.data_criado = new Date(obj.CREATED_AT).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });

  obj.hora_criado = new Date(obj.CREATED_AT).toLocaleTimeString('pt-BR', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  obj.whatsapp = obj.WHATSAPP ? createLinkWhatsapp(obj.WHATSAPP) : '';

  const update_active = async (event) => {
    event.preventDefault();
    let codigo = obj.PROCESSO_CODIGO;
    let updated_active = obj.active ? 0 : 1;

    try {
      setLoading(true);
      await API(END.sim.update.active, {
        codigo,
        active: updated_active,
      });
      obj.ACTIVE = updated_active;
      // setobj({
      //   ...obj,
      //   ACTIVE: updated_active,
      // });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const update_status_simulacao = async (id) => {
    //event.preventDefault();
    let codigo = obj.PROCESSO_CODIGO;

    try {
      setLoading(true);
      await API(END.processos.update.status_simulacao, {
        codigo,
        status_simulacao: id,
      });
      obj.STATUS_SIMULACAO_ID = id;
    } catch (error) {
      console.error(error);
    }
    obj.STATUS_SIMULACAO_ID = id;
    setLoading(false);
  };

  const delete_simulacao = async (event) => {
    event.preventDefault();
    const delete_sim = async () => {
      try {
        setLoading(true);
        await API(END.processos.delete, {
          codigo: obj.PROCESSO_CODIGO,
        });
      } catch (error) {
        console.error(error);
      }
      upd();
      setLoading(false);
    };

    Swal.fire({
      title: 'Tem certeza?',
      text: 'Você deseja deletar essa simulação? Essa ação não pode ser desfeita!',
      icon: 'warning',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonColor: '#c41000',
      confirmButtonText: 'Sim, deletar',
      iconColor: '#c41000',
      denyButtonColor: '#6cbe00',
      denyButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Ok, deletado!', '', 'success');
        delete_sim();
      } else if (result.isDenied) {
        Swal.fire('Tudo bem, vou manter a simulação aqui', '', 'info');
      }
    });
  };

  useEffect(() => {
    const dataBR = (stringDate) => {
      const regexYYYYMMDD = /^\d{4}-\d{2}-\d{2}$/;
      if (regexYYYYMMDD.test(stringDate)) {
        let parts = stringDate.split('-');
        return (
          parts[2] +
          '/' +
          parts[1].toString().padStart(2, '0') +
          '/' +
          parts[0].toString().padStart(2, '0')
        );
      }
      return stringDate;
    };

    const dateFormat = (stringDate) => {
      let formated = stringDate;
      if (stringDate) {
        if (stringDate.includes('T')) {
          formated = stringDate.split('T')[0];
        }
        return dataBR(formated);
      }
      return formated;
    };

    obj.tipo = obj.SIMULACAO_COMPLETA ? 'Completa' : 'Simplificada';
    obj.NASCIMENTO = dateFormat(obj.NASCIMENTO);
    obj.OUTRO_NASCIMENTO = obj.OUTRO_NASCIMENTO
      ? dateFormat(obj.OUTRO_NASCIMENTO)
      : null;
    obj.MAIS_VELHO = dateFormat(obj.MAIS_VELHO);

    const stringToArray = (string) => {
      if (string && string.includes(',')) {
        return string.split(',');
      }
      if (string && string.length > 0) {
        const newArray = [];
        newArray.push(string);
        return newArray;
      } else {
        return [];
      }
    };

    obj.COMPROVAR = stringToArray(obj.COMPROVAR);
    obj.OUTRO_COMPROVAR = stringToArray(obj.OUTRO_COMPROVAR);
    // console.log(obj)
  }, [obj]);

  return (
    <tr className='tableSimulacoes gorowMin750 gocol550 align-items-center justify-content-center w100'>
      <div className='hpMin75 p-2 border-bottom border-secondary w100 d-flex flex-row flex-wrap align-items-center justify-content-center'>
        <div className='my-2 d-flex flex-row flex-wrap align-items-center justify-content-center'>
          <span className='d-flex flex-row flex-wrap align-items-center justify-content-center'>
            <span className='wpMin175 breakAuto'>{obj.NOME}</span>
            <span className='gorowMin900 wpMin175 px-1 align-items-center justify-content-center'>
              <IconsUI
                info={ui.social.whatsapp}
                clsnm='svg10 cGreen me-1'
                link={obj.whatsapp}
                linkTargetToBlank={true}
                linkAriaLabel={`Whatsapp de ${obj.NOME}`}
                sideDivClass='mx-1 wpMin125 faCenter divIconsUi hovGreen justify-content-center wp px-1'
                sideText={obj.WHATSAPP}
              />
              <IconsUI
                info={ui.social.email}
                clsnm='wp15 wpMin20 hp15  me-1'
                link={`mailto:${obj.EMAIL}`}
                linkTargetToBlank={true}
                linkAriaLabel={`E-mail de ${obj.NOME}`}
                sideDivClass='mx-1 wp200 faCenter divIconsUi hovGreen justify-content-center wp px-1'
                sideText={obj.EMAIL}
                sideTextClass='breakWord'
              />
            </span>
          </span>
        </div>

        <div className='my-2 d-flex flex-row flex-wrap align-items-center justify-content-center'>
          <span className='gocol gorowMin1100 align-items-center justify-content-center'>
            <span className='gocol'>
              <span className='wpMin150 px-1'>{curr(obj.IMOVEL_VALOR)}</span>
              <span className='wpMin150 px-1'>
                {simpleDescription(obj.IMOVEL_DESCRICAO)}
              </span>
            </span>
            <span className='wpMin150 breakAuto px-1'>{obj.IMOVEL_CIDADE}</span>
          </span>
        </div>

        <div className='my-2 d-flex flex-row flex-wrap align-items-center justify-content-center'>
          <span className='gocol mAuto align-items-center justify-content-center'>
            <span className=' mAuto  gorow align-items-center justify-content-center'>
              <span className='mx-1'>{obj.data_criado}</span>
              <span className='mx-1'>{obj.hora_criado}</span>
            </span>

            <span className='gorow flex-wrap  mAuto align-items-center justify-content-center'>
              <span className='gorow py-1 mAuto  align-items-center justify-content-center'>
                <SimulacaoStatusDrop
                  simId={obj.STATUS_SIMULACAO_ID}
                  procCod={obj.PROCESSO_CODIGO}
                  statusChange={update_status_simulacao}
                />
                <IconsUI
                  info={ui.misc.eye.on}
                  clsnm='svg16 wp20 hp20 cGreen mousePointerHover'
                  tooltip='Visualizar detalhes'
                  click={handleShowSimulacao}
                  sideDivClass='mx-2'
                />

                <IconsUI
                  info={ui.folder.open}
                  clsnm='svg16 wp20 hp20 cGreen mousePointerHover'
                  tooltip='Abrir processo'
                  click={() => navigate(`/processo/${obj.PROCESSO_CODIGO}`)}
                  sideDivClass='mx-2'
                />

                <span className='mx-2'>
                  <BadgeFull full={obj.SIMULACAO_COMPLETA} />
                </span>

                <span className='mx-2'>
                  <BadgeCompor compor={obj.SIMULACAO_COMPLETA && obj.COMPOR} />
                </span>
              </span>
              <span className='gorow py-1 mAuto  align-items-center justify-content-center'>
                <span className='mx-2'>
                  <BadgeShare share={obj.COMPARTILHAR_CORRETOR} />
                </span>
                <IconsUI
                  info={ui.action.delete.trash}
                  clsnm={`svg14  ${
                    obj.STATUS_ID > 1
                      ? 'textContrastLow'
                      : 'cRed mousePointerHover'
                  }`}
                  tooltip={obj.STATUS_ID > 1 ? null : 'Deletar simulação'}
                  click={obj.STATUS_ID > 1 ? null : delete_simulacao}
                  sideDivClass='mx-2'
                />
              </span>
            </span>
          </span>
        </div>
      </div>

      <Modal
        show={showSimulacao}
        size='lg'
        fullscreen={modalSimulacaoFull}
        data-bs-theme={theme}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        onHide={handleCloseSimulacao}
      >
        <Modal.Header
          closeButton
          className='container py-3'
        >
          <Modal.Title className='fs16 fw300 w100'>
            <div className='gorow justify-content-between'>
              <span className='gocol'>
                <span className='fw100 fs10'>Simulação</span>
                <span className='fw600 textContrastColor'>{obj.NOME}</span>
              </span>
            </div>
          </Modal.Title>
          <IconsUI
            info={modalSimulacaoFull ? ui.fullscreen.exit : ui.fullscreen.enter}
            clsnm={` svg16 mousePointerHover hovGreen m-1`}
            id='normal'
            tooltip={
              modalSimulacaoFull
                ? 'Sair do modo tela cheia'
                : 'Entrar no modo tela cheia'
            }
            click={handleSetSimulacaoModalFull}
          />
        </Modal.Header>
        <Modal.Body>
          <SimulacaoModal
            obj={obj}
            upd={upd}
            on={obj.ACTIVE}
            changeActive={update_active}
            statusSimDrop={
              <SimulacaoStatusDrop
                simId={obj.STATUS_SIMULACAO_ID}
                procCod={obj.PROCESSO_CODIGO}
                statusChange={update_status_simulacao}
              />
            }
            buttonCOMPOR={
              <BadgeCompor compor={obj.SIMULACAO_COMPLETA && obj.COMPOR} />
            }
            buttonSHARE={<BadgeShare share={obj.COMPARTILHAR_CORRETOR} />}
            buttonFULL={<BadgeFull full={obj.SIMULACAO_COMPLETA} />}
            load={loading}
            del={delete_simulacao}
            full={modalSimulacaoFull}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showProcessoCreate}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        fullscreen={modalSimulacaoFull}
        data-bs-theme={theme}
        centered
        onHide={handleCloseProcessoCreate}
      >
        <Modal.Header
          closeButton
          className='container'
        >
          <Modal.Title className='fs16 fw300 w100 gocol'>
            <span className='fw100 fs10'>Novo Processo</span>
            <span className='fw600 textContrastColor'>{obj.NOME}</span>
          </Modal.Title>
          <IconsUI
            info={modalSimulacaoFull ? ui.fullscreen.exit : ui.fullscreen.enter}
            clsnm={` svg16 mousePointerHover hovGreen m-1`}
            id='normal'
            tooltip={
              modalSimulacaoFull
                ? 'Sair do modo tela cheia'
                : 'Entrar no modo tela cheia'
            }
            click={handleSetSimulacaoModalFull}
          />
        </Modal.Header>
        <Modal.Body>
          <ProcessoModal
            obj={obj}
            on={obj.active}
            changeActive={update_active}
            // buttonON={BadgeOnOff}
            load={loading}
            nobj={handleObj}
          />
        </Modal.Body>
        <Modal.Footer className='container'>
          <Button
            variant='secondary'
            size='sm'
            onClick={handleCloseProcessoCreate}
          >
            Sair
          </Button>
        </Modal.Footer>
      </Modal>
    </tr>
  );
}
